export const Colors = {
  gray: '#e3e3e3',
  blue: '#76a4f5',
  white: '#fff',
  black: '#000',
  yankeesBlue: '#1f233d',
  error: '#e65643',
  menuBackground: 'rgba(0, 0, 0, 0.2)',
  shopPolygon: [159, 114, 219, 255],
  dinePolygon: [110, 158, 49, 255],
  entertainmentPolygon: [255, 190, 0, 255],
  kioskPolygon: [227, 86, 222, 80],
  polygonNeutral: [130, 131, 133, 150],
  poligonLinked: [46, 217, 151, 150],
  transparentPolygon: [0, 0, 0, 0],
  polygonWithType: [245, 223, 29],
  whitePolygon: [255, 255, 255, 255],
  pathColor: [255, 186, 0, 255],
  // Resident title and logo color on canvas
  residenInfoColor: [247, 248, 243],
  focusedResidentInfoColor: [255, 255, 255]
};
