export const DEFAULT_GEO_JSON = {
  type: 'FeatureCollection',
  features: []
};

export const MODES = {
  DRAW: 'draw',
  SELECT_STORE: 'select_store',
  FOCUS_STORE: 'focus_store',
  GO_TO_STORE: 'go_to_store'
};

export const POLYGON_TYPES = {
  ELEVATOR: 'elevator',
  STAIRS: 'stairs',
  ATM: 'atm'
};

export const STAIRS_DIRECTIONS = {
  DOWN: 'down',
  UP: 'UP'
};
