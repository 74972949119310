import BaseAPIService from './BaseAPIService';

const ENDPOINTS = {
  FLOORS: '/floors/'
};

class FloorService extends BaseAPIService {
  get = () =>
    this.apiClient.get(ENDPOINTS.FLOORS, { params: { all_floors: true } });
}

export default new FloorService();
