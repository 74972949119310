import { createSelector } from 'reselect';

const floorStateSelector = (state) => state.floorsReducer;

export const floorsSelector = createSelector(floorStateSelector, (state) =>
  state.floors.sort(
    (first, second) => first.numberRepresentation - second.numberRepresentation
  )
);

export const selectedFloorSelector = createSelector(
  floorStateSelector,
  (state) => state.floors.find((floor) => floor.id === state.selectedFloor)
);

export const defaultFloorSelector = createSelector(
  floorStateSelector,
  (state) => state.floors.find((floor) => floor.id === state.defaultFloor)
);

export const getFloorByIdSelector = (floorId) =>
  createSelector(floorStateSelector, (state) =>
    state.floors.find((floor) => floor.id === floorId)
  );
