import { DrawLineStringMode } from '@nebula.gl/edit-modes';
import distance from '@turf/distance';
import { default as toPoint } from 'turf-point';

import { getCoordinates, mapToPoints } from '../helpers/geojson';

const SNAP_PRECISION = 0.008;

export class DrawSnappingLineStringMode extends DrawLineStringMode {
  constructor() {
    super(new DrawLineStringMode());
  }

  snapToPoint(event, props) {
    const points = mapToPoints({ geojson: props.data });
    const pointDistances = points
      .map((point) => ({
        ...point,
        distance: distance(toPoint(event.mapCoords), point),
      }))
      .sort(
        (firstPoint, secondPoint) => firstPoint.distance - secondPoint.distance
      )
      .filter((point) => point.distance <= SNAP_PRECISION);

    if (pointDistances.length) {
      return {
        ...event,
        mapCoords: getCoordinates(pointDistances[0]),
      };
    }

    return event;
  }

  handleClick(event, props) {
    super.handleClick(this.snapToPoint(event, props), props);
  }
}
