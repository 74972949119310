import { featureCollection } from '@turf/helpers';
import { createSelector } from 'reselect';

import { RESIDENT_TYPES } from '../../constants/resident';
import {
  checkIsResidentLinkedOnFloor,
  hasResidentLinkedOnFloor,
  isMatchingPolygon,
  isPolygonLinked,
  isSameResident,
} from '../../helpers/map-link';
import ResidentPolygonBlock from '../../models/ResidentPolygonBlock';
import {
  activeMapSelector,
  activeStoreSelector,
  focusedStoreSelector,
} from './mapSelectors';

const polygonStateSelector = (state) => state.polygonReducer;

export const polygonSelector = createSelector(
  polygonStateSelector,
  (state) => state.polygons
);

export const isResidentLinkedOnFloorSelector = createSelector(
  activeStoreSelector,
  activeMapSelector,
  polygonSelector,
  (activeStore, activeMap, polygons) =>
    hasResidentLinkedOnFloor(activeStore, polygons, activeMap)
);

export const isPolygonLinkedSelector = (polygon) =>
  createSelector(polygonSelector, (polygons) =>
    isPolygonLinked(polygon, polygons)
  );

export const residentPolygonSelector = (selectedPolygon) =>
  createSelector(polygonSelector, (polygons) =>
    polygons.find(({ polygon }) => isMatchingPolygon(polygon, selectedPolygon))
  );

export const polygonCentersWithResidentSelector = createSelector(
  activeMapSelector,
  polygonSelector,
  focusedStoreSelector,
  (activeMap, polygons, focusedStore) => {
    if (!activeMap) {
      return [];
    }

    return polygons
      .filter(
        (currentPolygon) =>
          currentPolygon.resident_type !== RESIDENT_TYPES.KIOSK
      )
      .map((currentPolygon) => {
        const polygon = activeMap.geojson.features.find(
          ({ properties }) => properties.id === currentPolygon.polygon.polygon
        );

        if (!polygon) {
          return null;
        }

        const isFocused =
          !!focusedStore && isSameResident(focusedStore, currentPolygon);

        return new ResidentPolygonBlock(currentPolygon, polygon, isFocused);
      })
      .filter((polygon) => polygon !== null);
  }
);

export const polygonCentersWithResidentNameSelector = createSelector(
  polygonCentersWithResidentSelector,
  (polygons) =>
    polygons.filter((residentPolygon) => !residentPolygon.isImageVisible())
);

export const isPolygonLinkedToSameResidentSelector = (polygon) =>
  createSelector(
    residentPolygonSelector(polygon),
    activeStoreSelector,
    (residentPolygon, activeStore) =>
      residentPolygon && isSameResident(activeStore, residentPolygon)
  );

export const focusedStoreGeoJsonPolygon = createSelector(
  activeMapSelector,
  focusedStoreSelector,
  polygonSelector,
  (activeMap, focusedStore, polygons) => {
    if (!activeMap) {
      return null;
    }

    if (!focusedStore) {
      return activeMap.geojson;
    }

    const linkedPolygon = polygons.find((residentPolygon) => {
      return checkIsResidentLinkedOnFloor(
        focusedStore,
        residentPolygon,
        activeMap
      );
    });

    if (!linkedPolygon) {
      return activeMap.geojson;
    }

    const geoJsonPolygons = activeMap.geojson.features.map((feature) => {
      if (isMatchingPolygon(linkedPolygon.polygon, feature)) {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            isFocused: true,
            type: linkedPolygon.resident_type,
          },
        };
      }

      return feature;
    });

    return featureCollection(geoJsonPolygons);
  }
);
