import { getShortestPathBetweenFloors } from './path-finder-between-floors';
import { getShortestPathToStore } from './path-finder-same-floor';

export default function calculateShortest(
  activeMap,
  fromPolygon,
  maps,
  floors,
  toPolygon,
  startPoint
) {
  return new Promise((resolve, reject) => {
    try {
      if (fromPolygon.polygon.map !== toPolygon.polygon.map) {
        resolve(
          getShortestPathBetweenFloors(
            activeMap,
            maps,
            floors,
            toPolygon,
            startPoint
          )
        );
      }

      resolve(getShortestPathToStore(activeMap, startPoint, toPolygon));
    } catch (e) {
      reject(e);
    }
  });
}
