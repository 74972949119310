import { ViewMode } from '@nebula.gl/edit-modes';
import { featureCollection } from '@turf/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from '../../../constants/colors';
import { MODES } from '../../../constants/stretch-modes';
import { getCoordinates } from '../../../helpers/geojson';
import { isSamePolygonId } from '../../../helpers/map-link';
import { stretchResidentInfoModeSet } from '../../../store/actions/mapActions';
import { showChooseStretchMode } from '../../../store/actions/modalActions';
import {
  residentPolygonBoundsSave,
  residentPolygonBoundsUpdate
} from '../../../store/actions/polygonActions';
import {
  isEditableSelector,
  stretchResidentInfoModeSelector
} from '../../../store/selectors/mapSelectors';
import { polygonCentersWithResidentSelector } from '../../../store/selectors/polygonSelectors';
import { ClickableGeoJsonLayer } from './base/ClickableGeoJsonLayer';

const IN_PROGRESS_ACTIONS = ['translating', 'rotating', 'scaling'];
const DONE_ACTIONS = ['translated', 'rotated', 'scaled'];

export const STRETCH_LAYER_ID = 'stretch-store-info-layer';

export default function useStretchResidentInfoLayer() {
  const dispatch = useDispatch();
  const polygonCentersWithResident = useSelector(
    polygonCentersWithResidentSelector
  );
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [geojson, setGeoJson] = useState(featureCollection([]));
  const strechResidentInfoMode = useSelector(stretchResidentInfoModeSelector);
  const isEditableMode = useSelector(isEditableSelector);

  useEffect(
    () => {
      const polygons = polygonCentersWithResident.map(residentPolygon => {
        return residentPolygon.bounds.getStretchBounds();
      });

      setGeoJson(featureCollection(polygons));
    },
    [polygonCentersWithResident]
  );

  const saveBounds = useCallback(
    residentPolygonBlock =>
      dispatch(residentPolygonBoundsSave(residentPolygonBlock)),
    [dispatch]
  );

  const updateBounds = useCallback(
    (residentPolygonBlock, updatedData, feature) => {
      const coordinates = getCoordinates(feature)[0];
      residentPolygonBlock.bounds.setBounds(coordinates);
      dispatch(residentPolygonBoundsUpdate(residentPolygonBlock));
      setGeoJson(updatedData);
    },
    [dispatch]
  );

  const onEdit = useCallback(
    ({ updatedData, editType, editContext: { featureIndexes } }) => {
      const feature = updatedData.features[featureIndexes[0]];
      const residentPolygonBlock = polygonCentersWithResident.find(block =>
        isSamePolygonId(block.polygon, feature)
      );

      if (IN_PROGRESS_ACTIONS.includes(editType)) {
        updateBounds(residentPolygonBlock, updatedData, feature);
      }

      if (DONE_ACTIONS.includes(editType)) {
        saveBounds(residentPolygonBlock);
      }
    },
    [polygonCentersWithResident, saveBounds, updateBounds]
  );

  const onSelect = useCallback(
    id => {
      if (!id || id === selectedPolygon) {
        dispatch(stretchResidentInfoModeSet(null));
        setSelectedPolygon(null);
        return;
      }

      dispatch(showChooseStretchMode());
      setSelectedPolygon(id);
    },
    [dispatch, selectedPolygon]
  );

  const selectedPolygonIndex = useMemo(
    () => {
      return geojson.features.findIndex(polygon => {
        return polygon.properties.id === selectedPolygon;
      });
    },
    [selectedPolygon, geojson.features]
  );

  const mode = useMemo(
    () => {
      const activeMode = MODES.find(item => {
        return item.key === strechResidentInfoMode;
      });

      return activeMode ? activeMode.mode : ViewMode;
    },
    [strechResidentInfoMode]
  );

  const layer = useMemo(
    () =>
      new ClickableGeoJsonLayer({
        id: STRETCH_LAYER_ID,
        data: geojson,
        mode,
        pickable: true,
        stroked: true,
        filled: true,
        selectedFeatureIndexes: [selectedPolygonIndex],
        onSelectLogo: onSelect,
        getFillColor: Colors.transparentPolygon,
        getLineColor: [0, 0, 0, 0],
        getLineWidth: 1,
        onEdit
      }),
    [geojson, mode, onEdit, onSelect, selectedPolygonIndex]
  );

  return [layer, isEditableMode];
}
