import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { featureCollection } from '@turf/helpers';
import PathFinder from 'react-geojson-path-finder';

import { mapPaths, mapToPoints } from '../geojson';
import { isMatchingPolygon } from '../map-link';

/**
 * Calculate the path to the store on selected floor
 */
export function getShortestPathToStore(activeMap, startPoint, store) {
  const path = mapPaths(activeMap);
  const points = mapToPoints(activeMap);

  // Get feature in geojson that represents store
  const storePolygon = activeMap.geojson.features.find((feature) => {
    return isMatchingPolygon(store.polygon, feature);
  });

  if (!storePolygon) {
    return [];
  }

  const endPoint = points.find((point) =>
    booleanPointInPolygon(point, storePolygon)
  );

  const pathFinder = new PathFinder(featureCollection(path));

  return [
    { floor: activeMap.floor, path: pathFinder.findPath(startPoint, endPoint) },
  ];
}
