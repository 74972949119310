import { createSelector } from 'reselect';

import { MODALS } from '../../constants/modals';

const modalStateSelector = (state) => state.modalReducer;

export const activeModal = createSelector(
  modalStateSelector,
  (state) => state.activeModal
);

export const activeModalContentSelector = createSelector(
  modalStateSelector,
  (state) => state.activeModalContent
);

export const isStoreShopModalVisibleSelector = createSelector(
  modalStateSelector,
  (state) => state.activeModal === MODALS.STORE_SHOP
);

export const isChooseStretchModeModalVisibleSelector = createSelector(
  modalStateSelector,
  (state) => state.activeModal === MODALS.CHOOSE_STRETCH
);
