import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';

const ModalBody = ({ children }) => <DialogContent>{children}</DialogContent>;

ModalBody.propTypes = {
  children: PropTypes.any,
};

export default ModalBody;
