function sendMessage(data) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(data);
  } else {
    window.parent.postMessage(data, '*');
  }
}

export function fireReadyEvent() {
  sendMessage('ready');
}

export function sendUserOnResident(residentPolygon) {
  const data = JSON.stringify({
    command: 'goToStore',
    data: {
      residentSlug: residentPolygon.content_object.slug,
      residentTitle: residentPolygon.content_object.title,
      type: residentPolygon.resident_type,
    },
  });

  sendMessage(data);
}

export function changeFloor(floorId) {
  sendMessage(
    JSON.stringify({
      command: 'changeFloor',
      data: {
        floorId,
      },
    })
  );
}
