/**
 * This is done because of DeckGL
 * In order for TextLayer to support Serbian Latin it doesn't accept UTF-8
 * but instead it accepts all letters that characters as array
 */
const cirilicCharacters = [
  'Q',
  'W',
  'E',
  'R',
  'T',
  'Y',
  'U',
  'I',
  'O',
  'P',
  'Š',
  'Đ',
  'A',
  'S',
  'D',
  'F',
  'G',
  'H',
  'J',
  'K',
  'L',
  'Č',
  'Ć',
  'Ž',
  'Z',
  'X',
  'C',
  'V',
  'B',
  'N',
  'M',
  ' ',
  '&',
  ':',
  '/',
  '\'',
  '-',
  ',',
  '.',
];

export const characterSet = cirilicCharacters;
