import { DrawPolygonMode, ModifyMode, ViewMode } from '@nebula.gl/edit-modes';

import { DrawSnappingLineStringMode } from './DrawSnappingLineStringMode';

export const DEFAULT_MODE = 'view';

export const DELETE_MODE = 'delete';

export const MARK_STAIRS_UP = 'mark_stairs_up';

export const MARK_STAIRS_DOWN = 'mark_stairs_down';

export const MARK_ELEVATOR = 'mark_elevator';

export const MARK_ATM = 'mark_atm';

export const MODES = [
  {
    key: 'view',
    name: 'View mode',
    mode: ViewMode
  },
  {
    key: 'draw-path',
    name: 'Draw path',
    mode: DrawSnappingLineStringMode
  },
  {
    key: 'draw-polygon',
    name: 'Draw polygon',
    mode: DrawPolygonMode
  },
  {
    key: 'modify',
    name: 'Modify item',
    mode: ModifyMode
  },
  {
    key: MARK_ELEVATOR,
    name: 'Mark elevator',
    mode: ViewMode
  },
  {
    key: MARK_STAIRS_UP,
    name: 'Mark stairs up',
    mode: ViewMode
  },
  {
    key: MARK_STAIRS_DOWN,
    name: 'Mark stairs down',
    mode: ViewMode
  },
  {
    key: MARK_ATM,
    name: 'Mark ATM',
    mode: ViewMode
  },
  {
    key: DELETE_MODE,
    name: 'Delete item',
    mode: ViewMode
  }
];
