import area from '@turf/area';
import centerOfMass from '@turf/center-of-mass';
import { lineString, polygon as convertToPolygon } from '@turf/helpers';
import length from '@turf/length';

import { getCoordinates } from './geojson';

export const getCenterPointsForPolygon = (polygon) =>
  centerOfMass(convertToPolygon(getCoordinates(polygon)));

export const polygonSize = (polygon) => area(polygon);

export const polygonLength = (polygon) => {
  const coordinates = getCoordinates(polygon)[0];

  return length(
    lineString([coordinates[0], coordinates[coordinates.length - 2]]),
    { units: 'meters' }
  );
};
