import { generateSvgLayer } from '../helpers/svgLayerConverter';

const MAP_WIDTH = 2000;
const MAP_HEIGHT = 4000;

export default class Floor {
  constructor(id, name, numberRepresentation, mapImage) {
    this.id = id;
    this.name = name;
    this.mapImage = mapImage;
    this.numberRepresentation = numberRepresentation;
    this.canvasImage = null;
  }

  async generateCanvasImage() {
    if (this.canvasImage) {
      return this.canvasImage;
    }

    this.canvasImage = await generateSvgLayer(
      // eslint-disable-line
      this.mapImage,
      MAP_WIDTH,
      MAP_HEIGHT
    );

    return this.canvasImage;
  }
}
