import styled from 'styled-components';

import { Colors } from '../../../constants/colors';

export const MenuItem = styled.button`
  width: 100%;
  background: transparent;
  color: #fff;
  border: 0;
  text-align: left;
  font-weight: 600;
  font-size: 1.3rem;
  background-color: ${(props) => (props.active ? Colors.blue : 'transparent')};

  &:hover {
    background-color: ${Colors.blue};
    cursor: pointer;
  }
`;
