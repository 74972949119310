import BaseAPIService from './BaseAPIService';

const ENDPOINTS = {
  AUTHORIZE: '/maps/users/authorize/',
};

class AuthService extends BaseAPIService {
  authorize = () => this.apiClient.get(ENDPOINTS.AUTHORIZE);
}

export default new AuthService();
