import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import Floor from '../../models/Floor';
import floorService from '../../services/FloorService';
import * as messageNotifier from '../../services/MessageNotifier';
import {
  activateFloor,
  FLOORS_GET,
  floorsSet,
  SELECT_FLOOR,
  SET_DEFAULT_FLOOR,
} from '../actions/floorActions';
import { RESET_MAP_FOCUS } from '../actions/mapActions';
import {
  defaultFloorSelector,
  getFloorByIdSelector,
} from '../selectors/floorSelectors';

function* getFloors() {
  try {
    const {
      data: { results },
    } = yield call(floorService.get);

    // Generate SVG in canvas for BitMapLayer to shop mall map
    const floors = results
      .map(
        (floor) =>
          new Floor(
            floor.id,
            floor.name,
            floor.number_representation,
            floor.map_image
          )
      )
      .sort(
        (first, second) =>
          first.numberRepresentation - second.numberRepresentation
      );

    if (floors.length) {
      yield floors[0].generateCanvasImage();
    }

    yield put(floorsSet(floors));
  } catch (e) {
    console.error(e);
  }
}

function* selectFloor({ payload }) {
  try {
    const floor = yield select(getFloorByIdSelector(payload));

    if (floor) {
      yield floor.generateCanvasImage();
    }

    yield put(activateFloor(payload));
  } catch (e) {
    console.error(e);
  }
}

function* handleResetMapFocus() {
  const floor = yield select(defaultFloorSelector);

  if (floor) {
    messageNotifier.changeFloor(floor.id);
  }
}

export default function* floorSagas() {
  yield all([
    takeLatest(FLOORS_GET, getFloors),
    takeLatest(SELECT_FLOOR, selectFloor),
    takeLatest(SET_DEFAULT_FLOOR, selectFloor),
    takeLatest(RESET_MAP_FOCUS, handleResetMapFocus),
  ]);
}
