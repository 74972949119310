import { POLYGON_TYPES } from '../../constants/map';

export const MAP_GET = 'MAP_GET';
export const MAPS_SET = 'MAPS_SET';
export const ACTIVE_MAP_SET = 'ACTIVE_MAP_SET';
export const MAP_SAVE = 'MAP_SAVE';
export const ACTIVE_STORE_SET = 'ACTIVE_STORE_SET';
export const LINK_POLYGON_TO_ID = 'LINK_POLYGON_TO_ID';
export const REMOVE_MAP_FEATURE = 'REMOVE_MAP_FEATURE';
export const IS_EDIT_MODE = 'IS_EDIT_MODE';
export const FOCUSED_STORE_SET = 'FOCUSED_STORE_SET';
export const FOCUS_STORE_VIEWPORT = 'FOCUS_STORE_VIEWPORT';
export const SET_VIEWPORT_TO_POLYGON = 'SET_VIEWPORT_TO_POLYGON';
export const VIEWPORT_SET = 'VIEWPORT_SET';
export const RESET_MAP_FOCUS = 'RESET_MAP_FOCUS';
export const STRETCH_RESIDENT_INFO_MODE_SET = 'STRETCH_RESIDENT_INFO_MODE_SET';
export const NAVIGATE_TO_STORE_SET = 'NAVIGATE_TO_STORE_SET';

export const MARK_POLYGON_AS_TYPE = 'MARK_POLYGON_AS_TYPE';
export const SET_POLYGON_TYPE = 'SET_POLYGON_TYPE';

export const mapGet = () => ({
  type: MAP_GET
});

export const mapsSet = payload => ({
  type: MAPS_SET,
  payload
});

export const mapSetAndMarkModified = payload => ({
  type: ACTIVE_MAP_SET,
  payload: {
    ...payload,
    isMapModified: true
  }
});

export const mapSetAndUnmarkModified = payload => ({
  type: ACTIVE_MAP_SET,
  payload: {
    ...payload,
    isMapModified: false
  }
});

export const mapSave = () => ({
  type: MAP_SAVE
});

export const activeStoreSet = payload => ({
  type: ACTIVE_STORE_SET,
  payload
});

export const linkPolygonToId = payload => ({
  type: LINK_POLYGON_TO_ID,
  payload
});

export const removeMapFeature = payload => ({
  type: REMOVE_MAP_FEATURE,
  payload
});

export const isEditModeSet = payload => ({
  type: IS_EDIT_MODE,
  payload
});

export const focusedStoreSet = payload => ({
  type: FOCUSED_STORE_SET,
  payload
});

export const navigateToStoreSet = payload => ({
  type: NAVIGATE_TO_STORE_SET,
  payload
});

export const focusStoreViewPort = payload => ({
  type: FOCUS_STORE_VIEWPORT,
  payload
});

export const setViewportToPolygon = payload => ({
  type: SET_VIEWPORT_TO_POLYGON,
  payload
});

export const viewportSet = payload => ({
  type: VIEWPORT_SET,
  payload
});

export const resetMapFocus = payload => ({
  type: RESET_MAP_FOCUS,
  payload
});

export const stretchResidentInfoModeSet = payload => ({
  type: STRETCH_RESIDENT_INFO_MODE_SET,
  payload
});

export const markPolygonAsStairs = payload => ({
  type: MARK_POLYGON_AS_TYPE,
  payload: {
    ...payload,
    type: POLYGON_TYPES.STAIRS
  }
});

export const markPolygonAsElevator = payload => ({
  type: MARK_POLYGON_AS_TYPE,
  payload: {
    polygon: payload,
    type: POLYGON_TYPES.ELEVATOR
  }
});

export const markPolygonAsAtm = payload => ({
  type: MARK_POLYGON_AS_TYPE,
  payload: {
    polygon: payload,
    type: POLYGON_TYPES.ATM
  }
});

export const setPolygonType = payload => ({
  type: SET_POLYGON_TYPE,
  payload
});
