import React from 'react';

import Map from '../components/Map/Map';

const MapScreen = () => {
  return (
    <>
      <Map />
    </>
  );
};

export default MapScreen;
