import axios from 'axios';
import qs from 'qs';

import config from '../config';

class HttpService {
  constructor(options = {}) {
    this.client = axios.create(options);
    this.client.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
  }

  attachHeaders(headers) {
    Object.assign(this.client.defaults.headers, headers);
  }
}

const options = {
  baseURL: config.apiUrl,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
};

const httpService = new HttpService(options);

export default httpService;
