import { POLYGON_TYPES } from '../constants/map';
import PolygonBounds from './PolygonBounds';

export default class StairsAndElevatorBounds extends PolygonBounds {
  constructor(polygon) {
    super(polygon, null);

    this.xOffset = 0.0009;
    this.yOffset = 0.0009;
  }

  isStairs() {
    return this.polygon.properties.type === POLYGON_TYPES.STAIRS;
  }
}
