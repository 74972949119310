import { format } from 'util';

import BaseAPIService from './BaseAPIService';

const ENDPOINTS = {
  POLYGONS: '/maps/polygons/',
  RESIDENT_POLYGON: '/maps/resident/%s/',
  RESIDENT_POLYGONS: '/maps/resident/all/',
  LINK_RESIDENT: '/maps/resident/link/',
  UNLINK_RESIDENT: '/maps/resident/%s/unlink/',
};

class PolygonService extends BaseAPIService {
  create = (payload) => this.apiClient.post(ENDPOINTS.POLYGONS, payload);

  delete = (id) => this.apiClient.delete(`${ENDPOINTS.POLYGONS}${id}/`);

  get = () => this.apiClient.get(ENDPOINTS.RESIDENT_POLYGONS);

  link = (payload) => this.apiClient.post(ENDPOINTS.LINK_RESIDENT, payload);

  unlink = (id) => this.apiClient.delete(format(ENDPOINTS.UNLINK_RESIDENT, id));

  updateBounds = (id, payload) =>
    this.apiClient.patch(format(ENDPOINTS.RESIDENT_POLYGON, id), payload);
}

export default new PolygonService();
