import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import useCloseModal from '../hooks/useCloseModal';

const DialogBody = styled.div`
  min-width: 30rem;
`;

function BaseModal({ children, title, ...props }) {
  const closeModal = useCloseModal();

  return (
    <Dialog {...props} onClose={closeModal}>
      <div>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogBody>{children}</DialogBody>
      </div>
    </Dialog>
  );
}

BaseModal.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
};

export default BaseModal;
