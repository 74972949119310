import { all } from 'redux-saga/effects';

import floorSagas from './floorSagas';
import mapSagas from './mapSagas';
import pathSagas from './pathSagas';
import polygonSagas from './polygonSagas';
import userSagas from './userSagas';

export default function* rootSaga() {
  yield all([
    userSagas(),
    floorSagas(),
    mapSagas(),
    polygonSagas(),
    pathSagas(),
  ]);
}
