import { combineReducers } from 'redux';

import floorsReducer from './floorReducer';
import mapReducer from './mapReducer';
import modalReducer from './modalReducer';
import pathReducer from './pathReducer';
import polygonReducer from './polygonReducer';
import userReducer from './userReducer';

export default combineReducers({
  userReducer,
  floorsReducer,
  mapReducer,
  modalReducer,
  polygonReducer,
  pathReducer,
});
