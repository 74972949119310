import { USER_SET } from '../actions/userActions';
import createReducer from '../util';

const initialState = {
  user: null,
};

export default createReducer(
  {
    [USER_SET]: userSet,
  },
  initialState
);

function userSet(state, action) {
  return {
    ...state,
    user: action.payload,
  };
}
