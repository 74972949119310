import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { MODES } from '../../../constants/map-modes';
import { MenuItem } from './MenuItem';

const ModeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MapModes = ({ onChange, activeMode }) => {
  return (
    <ModeContainer>
      {MODES.map((mode) => (
        <MenuItem
          key={mode.key}
          active={activeMode === mode.key}
          onClick={() => onChange(mode.key)}
        >
          {mode.name}
        </MenuItem>
      ))}
    </ModeContainer>
  );
};

MapModes.propTypes = {
  onChange: PropTypes.func,
  activeMode: PropTypes.string,
};

export default MapModes;
