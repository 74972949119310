import { IconLayer } from '@deck.gl/layers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Colors } from '../../../constants/colors';
import { ICON_MAPPING } from '../../../constants/path-layer';
import { getCoordinates } from '../../../helpers/geojson';
import { getMapStairsAndElevators } from '../../../helpers/map-link';
import StairsAndElevatorBounds from '../../../models/StairsAndElevatorBounds';
import {
  activeMapSelector,
  isEditableSelector
} from '../../../store/selectors/mapSelectors';

/**
 * Layer that renders stairs and elevators icons, they have separate model StairsAndElevatorBounds
 * to determine the bounds in which image should be drawn
 */
const useStairsAndElevatorLayer = () => {
  const activeMap = useSelector(activeMapSelector);
  const isEditMode = useSelector(isEditableSelector);

  const layers = useMemo(
    () => {
      const isLayerHidden = isEditMode || !activeMap;
      if (isLayerHidden) {
        return null;
      }

      return getMapStairsAndElevators(activeMap).map(feature => {
        return new IconLayer({
          id: feature.properties.id,
          billboard: true,
          iconAtlas: 'images/path-layer.png',
          iconMapping: ICON_MAPPING,
          data: [new StairsAndElevatorBounds(feature)],
          pickable: true,
          sizeMaxPixels: 200,
          sizeScale: 1,
          sizeUnits: 'meters',
          visible: true,
          getIcon: icon => (icon.isStairs() ? 'stairs' : 'elevator'),
          getPosition: icon => getCoordinates(icon.getCenter()),
          getSize: () => 200,
          getColor: () => Colors.pathColor
        });
      });
    },
    [activeMap, isEditMode]
  );

  return layers;
};

export default useStairsAndElevatorLayer;
