import PolygonBounds from './PolygonBounds';

export default class AtmBounds extends PolygonBounds {
  constructor(polygon) {
    super(polygon, null);

    this.xOffset = 0.0009;
    this.yOffset = 0.0009;
  }
}
