import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as BootstrapService from './services/BootstrapService';
import messageListener from './services/MessageListener';

messageListener.init();

BootstrapService.init().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root") // eslint-disable-line
  );
});
