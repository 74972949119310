export const SELECT_START_POINT_FROM_KIOSK = 'SELECT_START_POINT_FROM_KIOSK';
export const SET_START_POINT_FROM_POLYGON = 'SET_START_POINT_FROM_POLYGON';

export const selectStartPointFromKiosk = (payload) => ({
  type: SELECT_START_POINT_FROM_KIOSK,
  payload,
});

export const setStartPointFromPolygon = (payload) => ({
  type: SET_START_POINT_FROM_POLYGON,
  payload,
});
