import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { MODES } from '../../constants/stretch-modes';
import { stretchResidentInfoModeSet } from '../../store/actions/mapActions';
import useCloseModal from './hooks/useCloseModal';
import Modal from './modal/BaseModal';
import ModalBody from './modal/BaseModalBody';

function ChooseStretchMode({ isOpen }) {
  const dispatch = useDispatch();
  const closeModal = useCloseModal();

  const onChoose = (event) => {
    dispatch(stretchResidentInfoModeSet(event.target.value));
    closeModal();
  };

  return (
    <div>
      <Modal open={isOpen} title="Choose mode">
        <ModalBody>
          <RadioGroup onChange={onChoose}>
            {MODES.map((mode) => (
              <FormControlLabel
                key={mode.key}
                value={mode.key}
                control={<Radio />}
                label={mode.name}
              />
            ))}
          </RadioGroup>
        </ModalBody>
      </Modal>
    </div>
  );
}

ChooseStretchMode.propTypes = {
  isOpen: PropTypes.bool,
};

export default ChooseStretchMode;
