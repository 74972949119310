import { default as toPoint } from 'turf-point';

export const isPolygon = (feature) => feature.geometry.type === 'Polygon';

export const isLine = (feature) => feature.geometry.type === 'LineString';

export const isFeature = (feature) => feature.type === 'Feature';

export const getMapWithoutFeature = (map, object) => ({
  ...map.geojson,
  features: map.geojson.features.filter((feature) => feature !== object),
});

export const getCoordinates = (feature) => feature.geometry.coordinates;

export const mapPaths = (map) => map.geojson.features.filter(isLine);

export const mapToPoints = (map) => {
  const path = mapPaths(map);

  return path.flatMap((feature) => {
    return feature.geometry.coordinates.map(toPoint);
  });
};
