import store from '../store';
import { selectFloor } from '../store/actions/floorActions';
import {
  focusedStoreSet,
  navigateToStoreSet,
  resetMapFocus,
} from '../store/actions/mapActions';

const EVENTS = [
  {
    command: 'goToFloor',
    handle: ({ id }) => store.dispatch(selectFloor(id)),
  },
  {
    command: 'focusStore',
    handle: (data) => store.dispatch(focusedStoreSet(data)),
  },
  {
    command: 'goToStore',
    handle: (data) => store.dispatch(navigateToStoreSet(data)),
  },
  {
    command: 'resetMapFocus',
    handle: (data) => store.dispatch(resetMapFocus(data)),
  },
  {
    command: 'reloadMap',
    handle: () => window.location.reload(),
  },
];

class MessageListener {
  init() {
    window.addEventListener('message', this.eventHandler);
    document.addEventListener('message', this.eventHandler);
  }

  eventHandler = (receivedEvent) => {
    if (!receivedEvent.data) {
      return;
    }
    let receivedEventData = receivedEvent.data;
    if (typeof receivedEvent.data === 'string')
      receivedEventData = JSON.parse(receivedEvent.data);

    const eventHandler = EVENTS.find(
      (event) => event.command === receivedEventData.command
    );

    if (eventHandler && receivedEventData.data) {
      eventHandler.handle(receivedEventData.data);
    }
  };
}

export default new MessageListener();
