import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Colors } from '../../../constants/colors';
import { selectFloor } from '../../../store/actions/floorActions';
import {
  floorsSelector,
  selectedFloorSelector,
} from '../../../store/selectors/floorSelectors';
import { MenuItem } from './MenuItem';

const Container = styled(MenuItem).attrs({
  as: 'select',
})`
  border: 0;
  border-bottom: 1px solid ${Colors.white};
`;

const FloorsMenu = () => {
  const dispatch = useDispatch();
  const floors = useSelector(floorsSelector);
  const selectedFloor = useSelector(selectedFloorSelector);

  const onChange = ({ target }) => {
    const id = Number(target.value);
    dispatch(selectFloor(id));
  };

  const renderFloor = (floor) => <Floor floor={floor} key={floor.id} />;

  return (
    floors && (
      <Container value={selectedFloor && selectedFloor.id} onChange={onChange}>
        {floors.map(renderFloor)}
      </Container>
    )
  );
};

const Floor = ({ floor }) => <option value={floor.id}>{floor.name}</option>;

Floor.propTypes = {
  floor: PropTypes.object,
};

export default FloorsMenu;
