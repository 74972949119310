import React from 'react';
import { Provider } from 'react-redux';

import Modals from './components/modals/Modals';
import MapScreen from './screens/MapScreen';
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <Modals>
        <MapScreen />
      </Modals>
    </Provider>
  );
}

export default App;
