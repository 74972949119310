import { IconLayer, PathLayer } from '@deck.gl/layers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from '../../../constants/colors';
import { ICON_MAPPING } from '../../../constants/path-layer';
import { getCoordinates } from '../../../helpers/geojson';
import calculateShortest from '../../../helpers/path-finder/path-finder';
import LocationIconBounds from '../../../models/LocationIconBounds';
import * as messageNotifier from '../../../services/MessageNotifier';
import { setDefaultFloor } from '../../../store/actions/floorActions';
import { setViewportToPolygon } from '../../../store/actions/mapActions';
import { goToPolygonFloor } from '../../../store/actions/polygonActions';
import {
  floorsSelector,
  selectedFloorSelector
} from '../../../store/selectors/floorSelectors';
import {
  activeMapSelector,
  mapsSelector
} from '../../../store/selectors/mapSelectors';
import {
  closestStorePolygonSelector,
  startPointFeaturePolygonSelector,
  startPointResidentPolygonSelector,
  startPointSelector
} from '../../../store/selectors/pathSelectors';
import { polygonSelector } from '../../../store/selectors/polygonSelectors';

export const PATH_LAYER_ID = 'path-layer-id';

const usePathLayer = () => {
  const dispatch = useDispatch();
  const startPoint = useSelector(startPointSelector);
  const floors = useSelector(floorsSelector);
  const fromPolygon = useSelector(startPointResidentPolygonSelector);
  const pathStartPointPolygon = useSelector(startPointFeaturePolygonSelector);
  const toPolygon = useSelector(closestStorePolygonSelector(fromPolygon));
  const activeMap = useSelector(activeMapSelector);
  const maps = useSelector(mapsSelector);
  const activeFloor = useSelector(selectedFloorSelector);
  const polygons = useSelector(polygonSelector);
  const [directions, setDirections] = useState([]);
  const isUserOnKioskFloor =
    activeMap && fromPolygon && fromPolygon.polygon.map === activeMap.id;
  const shouldRecalculatePath = toPolygon && isUserOnKioskFloor;

  useEffect(
    () => {
      /**
       * Fix viewport to the kiosk position where we display poi icon
       * and move viewport to floor where is the kiosk, we push it to the end
       * of call stack because it's racing against initial viewport
       */
      if (pathStartPointPolygon) {
        setTimeout(() => {
          const map = maps.find(
            mapItem => mapItem.id === fromPolygon.polygon.map
          );
          dispatch(setViewportToPolygon(pathStartPointPolygon));
          dispatch(setDefaultFloor(map.floor));
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [pathStartPointPolygon]
  );

  useEffect(
    () => {
      if (fromPolygon && toPolygon) {
        dispatch(goToPolygonFloor(fromPolygon));
      }
    },
    [dispatch, fromPolygon, toPolygon, polygons]
  );

  useEffect(
    () => {
      if (!toPolygon) {
        setDirections([]);
      }
    },
    [toPolygon]
  );

  useEffect(
    () => {
      if (shouldRecalculatePath) {
        calculateShortest(
          activeMap,
          fromPolygon,
          maps,
          floors,
          toPolygon,
          startPoint
        ).then(setDirections);
        messageNotifier.changeFloor(activeMap.floor);
      }
    },
    [
      shouldRecalculatePath,
      activeMap,
      floors,
      fromPolygon,
      maps,
      startPoint,
      toPolygon
    ]
  );

  if (!startPoint || !activeFloor) {
    return [];
  }

  const direction = directions.find(item => item.floor === activeFloor.id);

  const paths =
    direction && direction.path
      ? [
        {
          path: direction.path.path,
          name: 'Path',
          color: Colors.pathColor
        }
      ]
      : [];

  return [
    paths.length > 0 &&
      new PathLayer({
        id: PATH_LAYER_ID,
        data: paths,
        pickable: false,
        widthScale: 1,
        widthMinPixels: 3,
        getPath: d => d.path,
        getColor: d => d.color
      }),
    // Renderes only on floor that's matching the location of kiosk
    isUserOnKioskFloor
      ? new IconLayer({
        id: 'user_location',
        billboard: true,
        iconAtlas: 'images/path-layer.png',
        iconMapping: ICON_MAPPING,
        data: [new LocationIconBounds(pathStartPointPolygon)],
        pickable: true,
        sizeMaxPixels: 100,
        sizeScale: 8,
        sizeUnits: 'meters',
        visible: true,
        getIcon: () => 'poi',
        getPosition: icon => getCoordinates(icon.getCenter()),
        getSize: () => 60,
        getColor: () => Colors.pathColor
      })
      : null
  ];
};

export default usePathLayer;
