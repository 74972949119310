import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { createSelector } from 'reselect';
import { default as toPoint } from 'turf-point';

import { isLine } from '../../helpers/geojson';
import { isMatchingPolygon, isSameResident } from '../../helpers/map-link';
import { focusedStoreSelector, mapsSelector } from './mapSelectors';
import { polygonSelector } from './polygonSelectors';

const pathStateSelector = (state) => state.pathReducer;

export const startPolygonSelector = createSelector(
  pathStateSelector,
  (pathState) => pathState.startPolygon
);

export const startPointResidentPolygonSelector = createSelector(
  startPolygonSelector,
  polygonSelector,
  (startPolygon, polygons) => {
    if (!startPolygon) {
      return null;
    }

    return polygons.find(
      (polygon) =>
        polygon.content_object.id === startPolygon.id &&
        polygon.resident_type === startPolygon.type
    );
  }
);

export const startPointFeaturePolygonSelector = createSelector(
  startPointResidentPolygonSelector,
  mapsSelector,
  (startPolygon, maps) => {
    if (!startPolygon || !maps.length) {
      return null;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const map of maps) {
      const polygon = map.geojson.features.find((feature) =>
        isMatchingPolygon(startPolygon.polygon, feature)
      );

      if (polygon) {
        return polygon;
      }
    }

    return null;
  }
);

export const closestStorePolygonSelector = (closestToPolygon) =>
  createSelector(
    focusedStoreSelector,
    polygonSelector,
    (focusedStore, polygons) => {
      if (!focusedStore || !polygons || !closestToPolygon) {
        return null;
      }

      const residentPolygons = polygons.filter((residentPolygon) => {
        return isSameResident(focusedStore, residentPolygon);
      });

      const closestResidentPolygon = residentPolygons.find(
        (residentPolygon) => {
          return residentPolygon.polygon.map === closestToPolygon.polygon.map;
        }
      );

      return closestResidentPolygon || residentPolygons[0];
    }
  );

/**
 * Find the start point where path is inside start polygon
 * e.g. Find the point that is inside current open map on kiosk
 */
export const startPointSelector = createSelector(
  startPointResidentPolygonSelector,
  mapsSelector,
  (startPolygon, maps) => {
    if (!startPolygon) {
      return null;
    }

    const activeMap = maps.find((map) => map.id === startPolygon.polygon.map);

    if (!activeMap) {
      return null;
    }

    const points = activeMap.geojson.features
      .filter((feature) => isLine(feature))
      .flatMap((feature) => {
        return feature.geometry.coordinates.map((coordinates) =>
          toPoint(coordinates)
        );
      });

    const polygon = activeMap.geojson.features.find((feature) =>
      isMatchingPolygon(startPolygon.polygon, feature)
    );

    if (!polygon || !points) {
      return null;
    }

    const pointInPolygon = points.find((point) =>
      booleanPointInPolygon(point, polygon)
    );

    return pointInPolygon;
  }
);
