import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { LINK_TYPES } from '../../constants/resident';
import {
  residentPolygonRemove,
  residentPolygonSet,
} from '../../store/actions/polygonActions';
import { isKioskSelector } from '../../store/selectors/mapSelectors';
import { activeModalContentSelector } from '../../store/selectors/modalSelectors';
import {
  isPolygonLinkedSelector,
  isPolygonLinkedToSameResidentSelector,
  isResidentLinkedOnFloorSelector,
} from '../../store/selectors/polygonSelectors';
import ErrorMessage from '../shared/ErrorMessage';
import useCloseModal from './hooks/useCloseModal';
import Modal from './modal/BaseModal';
import ModalBody from './modal/BaseModalBody';

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.isDeleteVisible ? 'space-between' : 'flex-end'};
`;

const LINK_TYPE_NAMES = [
  {
    key: LINK_TYPES.TITLE,
    name: 'Link title',
  },
  {
    key: LINK_TYPES.LOGO,
    name: 'Link logo',
  },
];

function StoreShopModal({ isOpen }) {
  const dispatch = useDispatch();
  const closeModal = useCloseModal();
  const isKiosk = useSelector(isKioskSelector);
  const activeModalContent = useSelector(activeModalContentSelector);
  const isResidentLinkedOnFloor = useSelector(isResidentLinkedOnFloorSelector);
  const isPolygonLinked = useSelector(
    isPolygonLinkedSelector(activeModalContent.polygon)
  );
  const isSameResidentOnPolygon = useSelector(
    isPolygonLinkedToSameResidentSelector(activeModalContent.polygon)
  );
  const [linkType, setLinkType] = useState(LINK_TYPES.TITLE);

  const linkPolygon = () => {
    dispatch(
      residentPolygonSet({ polygon: activeModalContent.polygon, linkType })
    );
    closeModal();
  };

  const deleteLink = () => {
    dispatch(residentPolygonRemove(activeModalContent.polygon));
    closeModal();
  };

  const renderAlreadyLinkedWarning = () => (
    <ErrorMessage>
      This polygon is already linked to another entity!
    </ErrorMessage>
  );

  const renderFloorWarning = () => (
    <ErrorMessage>
      This tenant is already linked on this floor! This action will remove old
      link and keep this as new one.
    </ErrorMessage>
  );

  const renderBodyMessage = () =>
    isSameResidentOnPolygon ? (
      <p>This store is already linked to this polygon.</p>
    ) : (
      <p>This polygon will be linked to this entity. Are you sure?</p>
    );

  const renderLinkTypes = () => (
    <RadioGroup
      value={linkType}
      onChange={({ target }) => setLinkType(target.value)}
    >
      {LINK_TYPE_NAMES.map((item) => (
        <FormControlLabel
          key={item.key}
          value={item.key}
          control={<Radio />}
          label={item.name}
        />
      ))}
    </RadioGroup>
  );

  return (
    <div>
      <Modal open={isOpen} title="Link entity">
        <ModalBody>
          {!isSameResidentOnPolygon && !isKiosk && renderLinkTypes()}
          {isPolygonLinked &&
            !isSameResidentOnPolygon &&
            renderAlreadyLinkedWarning()}
          {isResidentLinkedOnFloor &&
            !isSameResidentOnPolygon &&
            renderFloorWarning()}
          {renderBodyMessage()}
          <Actions isDeleteVisible={isPolygonLinked}>
            {isPolygonLinked && (
              <Button
                color="secondary"
                variant="contained"
                onClick={deleteLink}
              >
                Delete this link
              </Button>
            )}
            <div>
              <Button color="default" onClick={closeModal}>
                Close
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={linkPolygon}
                disabled={isSameResidentOnPolygon}
              >
                Link
              </Button>
            </div>
          </Actions>
        </ModalBody>
      </Modal>
    </div>
  );
}

StoreShopModal.propTypes = {
  isOpen: PropTypes.bool,
};

export default StoreShopModal;
