export const USER_SET = 'USERS_SET';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const USER_GET = 'USERS_GET';

export function userSet(payload) {
  return {
    type: USER_SET,
    payload,
  };
}

export function userTokenSet(payload) {
  return {
    type: USER_TOKEN_SET,
    payload,
  };
}
