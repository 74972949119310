import { format } from 'util';

import BaseAPIService from './BaseAPIService';

const ENDPOINTS = {
  GET_KIOSK: '/kiosk/%s/',
};

class KioskService extends BaseAPIService {
  get = (address) => this.apiClient.get(format(ENDPOINTS.GET_KIOSK, address));
}

export default new KioskService();
