import { useDispatch } from 'react-redux';

import { closeModal as closeModalAction } from '../../../store/actions/modalActions';

const useCloseModal = () => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(closeModalAction());

  return closeModal;
};

export default useCloseModal;
