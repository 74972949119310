import bearing from '@turf/bearing';
import {
  bearingToAzimuth,
  point,
  polygon as convertToPolygon
} from '@turf/helpers';
import polygonToLine from '@turf/polygon-to-line';

import { getCoordinates } from '../helpers/geojson';
import * as geoJsonCalculator from '../helpers/geoJsonCalculator';

const INITIAL_ANGLE = 90;

export default class PolygonBounds {
  constructor(polygon, bounds, dimensions, linkType) {
    if (linkType === 'logo' && dimensions) {
      this.xOffset = this.getXOffset(dimensions);
    } else {
      this.xOffset = 0.003;
    }

    this.polygon = polygon;
    this.bounds = bounds;
    this.yOffset = 0.001;
  }

  getCenter() {
    return geoJsonCalculator.getCenterPointsForPolygon(this.polygon);
  }

  getBoundsCenter() {
    return geoJsonCalculator.getCenterPointsForPolygon(this.getStretchBounds());
  }

  getBoundsAngle() {
    if (!this.bounds) {
      return 0;
    }

    const line = polygonToLine(this.createBoundsPolygonToPolygon(this.bounds));
    const coordinates = getCoordinates(line);
    const start = point(coordinates[0]);
    const end = point(coordinates[coordinates.length - 2]);

    return bearingToAzimuth(bearing(start, end)) - INITIAL_ANGLE;
  }

  getXOffset(dimensions) {
    const ratio =
      dimensions.height > dimensions.width
        ? dimensions.width / dimensions.height
        : dimensions.height / dimensions.width;
    let xOffset = 100 / ratio;
    xOffset = parseFloat(`0.00${xOffset}`);

    // Some images need to be stretched a little more
    // this is noticeable when width and height are the same sizes
    if (dimensions.height <= dimensions.width) {
      xOffset += 0.00027;
    }

    return xOffset;
  }

  getImageBounds() {
    if (this.bounds) {
      return this.bounds;
    }

    const center = this.getCenter();
    const coordinates = getCoordinates(center);

    const startImageBounds = [
      coordinates[0] - this.xOffset,
      coordinates[1] - this.yOffset
    ];
    const endImageBounds = [
      coordinates[0] + this.xOffset,
      coordinates[1] + this.yOffset
    ];

    return [...startImageBounds, ...endImageBounds];
  }

  getStretchBounds() {
    if (this.bounds) {
      return this.createBoundsPolygonToPolygon(this.bounds);
    }

    const center = this.getCenter();
    const coordinates = getCoordinates(center);

    const bottomLeftBound = [
      coordinates[0] - this.xOffset,
      coordinates[1] - this.yOffset
    ];
    const bottomRightBound = [
      coordinates[0] + this.xOffset,
      coordinates[1] - this.yOffset
    ];

    const topRightBound = [
      coordinates[0] + this.xOffset,
      coordinates[1] + this.yOffset
    ];
    const topLeftBound = [
      coordinates[0] - this.xOffset,
      coordinates[1] + this.yOffset
    ];

    return this.createBoundsPolygonToPolygon([
      bottomLeftBound,
      topLeftBound,
      topRightBound,
      bottomRightBound,
      bottomLeftBound
    ]);
  }

  createBoundsPolygonToPolygon(bounds) {
    return convertToPolygon([bounds], {
      id: this.polygon.properties.id
    });
  }

  setBounds(bounds) {
    this.bounds = bounds;
  }

  getBounds() {
    return this.bounds;
  }
}
