import { BitmapLayer } from '@deck.gl/layers';

const useFloorLayer = (floor) => {
  if (!floor || !floor.canvasImage) {
    return null;
  }

  return new BitmapLayer({
    id: 'bitmap-layer',
    bounds: [-122.539, 37.7045, -122.405, 37.929],
    image: floor.canvasImage,
    updateTriggers: {
      floor,
    },
  });
};

export default useFloorLayer;
