import styled from 'styled-components';

import { Colors } from '../../constants/colors';

const ErrorMessage = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${Colors.error};
`;

export default ErrorMessage;
