import { BitmapLayer } from '@deck.gl/layers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { focusedStoreSelector } from '../../../store/selectors/mapSelectors';
import { polygonCentersWithResidentSelector } from '../../../store/selectors/polygonSelectors';

const useResidentLogoLayer = () => {
  const polygonCentersWithResident = useSelector(
    polygonCentersWithResidentSelector
  );
  const focusedStore = useSelector(focusedStoreSelector);

  const layers = useMemo(() => {
    return polygonCentersWithResident
      .filter((residentPolygon) => residentPolygon.isImageVisible())
      .map((residentPolygon) => {
        return new BitmapLayer({
          id: residentPolygon.getIdentifier(),
          bounds: residentPolygon.bounds.getImageBounds(),
          image: residentPolygon.getImageLayer(),
          pickable: true,
          updateTriggers: {
            focusedStore,
          },
        });
      });
  }, [polygonCentersWithResident, focusedStore]);

  return layers;
};

export default useResidentLogoLayer;
