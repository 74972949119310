import {
  ACTIVATE_FLOOR,
  FLOORS_SET,
  SET_DEFAULT_FLOOR,
} from '../actions/floorActions';
import { RESET_MAP_FOCUS } from '../actions/mapActions';
import createReducer from '../util';

/**
 * Default floor is used when we switch between floors
 * and then map lose focus, user goes to the other screen
 * after that we send RESET_MAP_FOCUS to center map again on correct floor
 * and on correct viewport
 */
const initialState = {
  floors: [],
  defaultFloor: null,
  selectedFloor: null,
};

export default createReducer(
  {
    [FLOORS_SET]: floorsSet,
    [ACTIVATE_FLOOR]: activateFloor,
    [SET_DEFAULT_FLOOR]: setDefaultFloor,
    [RESET_MAP_FOCUS]: resetFloor,
  },
  initialState
);

const getFirstFloorId = (floors) => {
  const sortedFloors = floors.sort(
    (first, second) => first.numberRepresentation - second.numberRepresentation
  );

  if (!sortedFloors.length) {
    return null;
  }

  return sortedFloors[0].id;
};

function floorsSet(state, action) {
  return {
    ...state,
    floors: action.payload,
    selectedFloor: state.selectedFloor || getFirstFloorId(action.payload),
  };
}

function setDefaultFloor(state, action) {
  return {
    ...state,
    defaultFloor: action.payload,
  };
}

function resetFloor(state) {
  return {
    ...state,
    selectedFloor: state.defaultFloor || getFirstFloorId(state.floors),
  };
}

function activateFloor(state, action) {
  return {
    ...state,
    selectedFloor: action.payload,
  };
}
