import { GeoJsonLayer } from '@deck.gl/layers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from '../../../constants/colors';
import { focusStoreViewPort } from '../../../store/actions/mapActions';
import {
  isFocusStoreModeSelector,
  isGoToStoreModeSelector,
  isPreviewModeSelector,
} from '../../../store/selectors/mapSelectors';
import { focusedStoreGeoJsonPolygon } from '../../../store/selectors/polygonSelectors';
import * as polygonColors from '../helpers/polygonColors';

export const MOBILE_GEO_JSON_LAYER_ID = 'store-geojson';

const useMobileGeoJsonLayer = () => {
  const dispatch = useDispatch();
  const isFocusStoreMode = useSelector(isFocusStoreModeSelector);
  const isGoToStoreMode = useSelector(isGoToStoreModeSelector);
  const isPreviewMode = useSelector(isPreviewModeSelector);
  const geoJson = useSelector(focusedStoreGeoJsonPolygon);
  let layer = null;

  const isFocused = (feature) => feature.properties.isFocused;

  useEffect(() => {
    if (isGoToStoreMode) {
      return;
    }

    if (!isFocusStoreMode || !geoJson) {
      return;
    }

    const polygon = geoJson.features.find(isFocused);
    if (!polygon) {
      return;
    }

    setTimeout(() => {
      dispatch(focusStoreViewPort(polygon));
    }, 300);
  }, [dispatch, isFocusStoreMode, geoJson, isGoToStoreMode]);

  const getColor = (polygon) => {
    if (isFocused(polygon)) {
      return polygonColors.residentTypeToColor(polygon.properties.type);
    }

    return Colors.transparentPolygon;
  };

  layer = geoJson
    ? new GeoJsonLayer({
      id: MOBILE_GEO_JSON_LAYER_ID,
      pickable: true,
      data: geoJson,
      getFillColor: getColor,
      getLineColor: getColor,
      enableSnapping: true,
    })
    : null;

  return [layer, isPreviewMode];
};

export default useMobileGeoJsonLayer;
