import BaseAPIService from './BaseAPIService';

const ENDPOINTS = {
  MAP_GET: '/maps/get/',
  MAP_SAVE: '/maps/save/',
};

class MapService extends BaseAPIService {
  get = () => this.apiClient.get(ENDPOINTS.MAP_GET);

  save = (payload) => this.apiClient.post(ENDPOINTS.MAP_SAVE, payload);
}

export default new MapService();
