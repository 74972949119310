export const CREATE_POLYGON = 'CREATE_POLYGON';
export const REMOVE_POLYGON = 'REMOVE_POLYGON';
export const POLYGONS_SET = 'POLYGONS_SET';
export const LINK_RESIDENT_TO_POLYGON = 'LINK_RESIDENT_TO_POLYGON';
export const RESIDENT_POLYGON_SET = 'RESIDENT_POLYGON_SET';
export const RESIDENT_POLYGON_REMOVE = 'RESIDENT_POLYGON_REMOVE';
export const ADD_RESIDENT_POLYGON = 'ADD_RESIDENT_POLYGON';
export const REMOVE_RESIDENT_POLYGON = 'REMOVE_RESIDENT_POLYGON';
export const RESIDENT_POLYGON_BOUNDS_UPDATE = 'RESIDENT_POLYGON_BOUNDS_UPDATE';
export const RESIDENT_POLYGON_BOUNDS_SAVE = 'RESIDENT_POLYGON_BOUNDS_SAVE';
export const GO_TO_POLYGON_FLOOR = 'GO_TO_POLYGON_FLOOR';

export const goToPolygonFloor = (payload) => ({
  type: GO_TO_POLYGON_FLOOR,
  payload,
});

export const createPolygon = (payload) => ({
  type: CREATE_POLYGON,
  payload,
});

export const removePolygon = (payload) => ({
  type: REMOVE_POLYGON,
  payload,
});

export const polygonsSet = (payload) => ({
  type: POLYGONS_SET,
  payload,
});

export const linkResidentToPolygon = (payload) => ({
  type: LINK_RESIDENT_TO_POLYGON,
  payload,
});

export const residentPolygonSet = (payload) => ({
  type: RESIDENT_POLYGON_SET,
  payload,
});

export const residentPolygonRemove = (payload) => ({
  type: RESIDENT_POLYGON_REMOVE,
  payload,
});

export const addResidentPolygon = (payload) => ({
  type: ADD_RESIDENT_POLYGON,
  payload,
});

export const removeResidentPolygon = (payload) => ({
  type: REMOVE_RESIDENT_POLYGON,
  payload,
});

export const residentPolygonBoundsUpdate = (payload) => ({
  type: RESIDENT_POLYGON_BOUNDS_UPDATE,
  payload,
});

export const residentPolygonBoundsSave = (payload) => ({
  type: RESIDENT_POLYGON_BOUNDS_SAVE,
  payload,
});
