import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { featureCollection, lineString } from '@turf/helpers';
import length from '@turf/length';
import pointToLineDistance from '@turf/point-to-line-distance';
import polygonToLine from '@turf/polygon-to-line';
import PathFinder from 'react-geojson-path-finder';

import { POLYGON_TYPES, STAIRS_DIRECTIONS } from '../../constants/map';
import { getPathPointFromPolygon } from '../find-points';
import { mapPaths, mapToPoints } from '../geojson';
import { getMapStairsAndElevators, isPolygonOfType } from '../map-link';
import { getShortestPathToStore } from './path-finder-same-floor';

const DISTANCE_BETWEEN_FLOORS_FOR_ELEVATOR = 2;

export function getShortestPathBetweenFloors(
  activeMap,
  maps,
  floors,
  toPolygon,
  startPoint
) {
  const residentMap = maps.find((map) => map.id === toPolygon.polygon.map);
  const shortestPathOnCurrentFloor = getShortestPathToNextFloor(
    activeMap,
    floors,
    startPoint,
    residentMap
  );
  const startPointOnResidentFloor = getNextStartFromPreviousEndPoint(
    residentMap,
    shortestPathOnCurrentFloor.endPoint,
    shortestPathOnCurrentFloor.type
  );

  const pathOnCurrentFloor = {
    floor: activeMap.floor,
    path: shortestPathOnCurrentFloor,
  };

  const pathOnResidentFloor = getShortestPathToStore(
    residentMap,
    startPointOnResidentFloor,
    toPolygon
  );

  return [pathOnCurrentFloor, ...pathOnResidentFloor];
}

/**
 * Calculate the point from which we need to show map direction on resident floor
 * First floor is where is the Kiosk (original polygon), and next floor is
 * where is the resident
 *
 * @object map - map of next floor
 * @object previousEndPoint - point to which user was directed on previous floor
 * @string previousEndPointType - If user was directed to elevator, search for elevator on
 * the next floor instead of searching for all types
 */
function getNextStartFromPreviousEndPoint(
  map,
  previousEndPoint,
  previousEndPointType
) {
  const nextStairsOrElevator = map.geojson.features
    .filter((feature) => isPolygonOfType(feature, previousEndPointType))
    .map((feature) => {
      return {
        ...feature,
        distance: pointToLineDistance(previousEndPoint, polygonToLine(feature)),
      };
    })
    .sort((first, second) => first.distance - second.distance)[0];

  return getPathPointFromPolygon(map, nextStairsOrElevator);
}

function getStairsDirectionsBetweenFloors(
  floors,
  activeMapFloorId,
  residentFloorId
) {
  const activeMapFloor = floors.find(({ id }) => activeMapFloorId === id)
    .numberRepresentation;
  const residentFloor = floors.find(({ id }) => residentFloorId === id)
    .numberRepresentation;

  if (activeMapFloor === residentFloor) {
    return Object.values(STAIRS_DIRECTIONS);
  }

  return activeMapFloor < residentFloor
    ? [STAIRS_DIRECTIONS.UP]
    : [STAIRS_DIRECTIONS.DOWN];
}

function hasAllDirections(stairsDirectionsToFind, stairsDirections) {
  return stairsDirectionsToFind.every((stairsDirection) =>
    stairsDirections.includes(stairsDirection)
  );
}

/**
 * If the distance between floors is 2 user is going to elevator
 *  - user goes from 0 to second floor, then we are sending him to elevator
 *  - user goes from 0 to first floor, then we are sending him to stairs
 */
function isDistanceBetweenFloorsForElevator(
  activeMapFloorId,
  residentFloorId,
  floors
) {
  const activeMapFloor = floors.find(({ id }) => activeMapFloorId === id)
    .numberRepresentation;
  const residentFloor = floors.find(({ id }) => residentFloorId === id)
    .numberRepresentation;

  return (
    Math.abs(activeMapFloor - residentFloor) ===
    DISTANCE_BETWEEN_FLOORS_FOR_ELEVATOR
  );
}

/**
 * Calculate the path to the nearest elevator or stairs
 * based on where is the Kiosk
 */
function getShortestPathToNextFloor(
  activeMap,
  floors,
  startPoint,
  residentMap
) {
  const shouldGoWithElevator = isDistanceBetweenFloorsForElevator(
    activeMap.floor,
    residentMap.floor,
    floors
  );
  const stairsAndElevators = getMapStairsAndElevators(
    activeMap
  ).filter((feature) =>
    isPolygonOfType(
      feature,
      shouldGoWithElevator ? POLYGON_TYPES.ELEVATOR : POLYGON_TYPES.STAIRS
    )
  );
  const path = mapPaths(activeMap);
  const points = mapToPoints(activeMap);
  const stairsDirections = getStairsDirectionsBetweenFloors(
    floors,
    activeMap.floor,
    residentMap.floor
  );

  // Pull point in path that is overlapping with stairs/elevator polygon
  const stairsAndElevatorPoints = stairsAndElevators
    .filter(
      ({ properties }) =>
        !properties.directions ||
        !properties.directions.every(Boolean) ||
        hasAllDirections(stairsDirections, properties.directions)
    )
    .map((feature) => {
      return {
        point: points.find((point) => booleanPointInPolygon(point, feature)),
        feature,
      };
    })
    .filter((feature) => feature.point);

  if (!stairsAndElevatorPoints.length) {
    return {};
  }

  const paths = stairsAndElevatorPoints
    .map(({ point, feature }) => {
      // Calculate the shorest path to every elevator/stairs
      const pathFinder = new PathFinder(featureCollection(path));
      return {
        ...pathFinder.findPath(startPoint, point),
        endPoint: point,
        type: feature.properties.type,
      };
    })
    .map((pathToNextFloor) => {
      // Get distance to each stairs and elevators and sort them
      // so that we get shortest path to next floor
      return {
        ...pathToNextFloor,
        distance: length(lineString(pathToNextFloor.path), { units: 'meters' }),
      };
    })
    .sort((first, second) => first.distance - second.distance);

  // Take the shortest path
  return paths[0];
}
