export const FLOORS_GET = 'FLOOR_GET';
export const FLOORS_SET = 'FLOOR_SET';
export const SELECT_FLOOR = 'SELECT_FLOOR';
export const ACTIVATE_FLOOR = 'ACTIVATE_FLOOR';
export const SET_DEFAULT_FLOOR = 'SET_DEFAULT_FLOOR';

export const floorsGet = () => ({
  type: FLOORS_GET,
});

export const floorsSet = (payload) => ({
  type: FLOORS_SET,
  payload,
});

export const selectFloor = (payload) => ({
  type: SELECT_FLOOR,
  payload,
});

export const setDefaultFloor = (payload) => ({
  type: SET_DEFAULT_FLOOR,
  payload,
});

export const activateFloor = (payload) => ({
  type: ACTIVATE_FLOOR,
  payload,
});
