import { MODAL_CLOSE, MODAL_SHOW } from '../actions/modalActions';
import createReducer from '../util';

const initialState = {
  activeModal: null,
  activeModalContent: null,
};

export default createReducer(
  {
    [MODAL_SHOW]: showModal,
    [MODAL_CLOSE]: closeModal,
  },
  initialState
);

function showModal(state, { payload: { type, content } }) {
  return {
    ...state,
    activeModal: type,
    activeModalContent: content,
  };
}

function closeModal(state) {
  return {
    ...state,
    activeModal: null,
    activeModalContent: null,
  };
}
