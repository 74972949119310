import { TextLayer } from '@deck.gl/layers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { characterSet } from '../../../constants/characterSet';
import { TEXT_FONT } from '../../../constants/fonts';
import { getCoordinates } from '../../../helpers/geojson';
import * as geoJsonCalculator from '../../../helpers/geoJsonCalculator';
import { polygonCentersWithResidentNameSelector } from '../../../store/selectors/polygonSelectors';

const useResidentNameLayer = () => {
  const polygonCentersWithResidentName = useSelector(
    polygonCentersWithResidentNameSelector
  );

  const calculateFontSizeFromPolygonLength = (polygon) => {
    const area = geoJsonCalculator.polygonLength(
      polygon.bounds.getStretchBounds()
    );

    return area / (polygon.getDisplayTitle().length / 2);
  };

  const layer = useMemo(
    () =>
      new TextLayer({
        id: 'resident-name-layer',
        data: polygonCentersWithResidentName,
        pickable: false,
        billboard: false,
        fontFamily: TEXT_FONT,
        getPosition: (polygon) =>
          getCoordinates(polygon.bounds.getBoundsCenter()),
        getAngle: (polygon) => polygon.bounds.getBoundsAngle(),
        getText: (polygon) => polygon.getDisplayTitle(),
        getSize: calculateFontSizeFromPolygonLength,
        getColor: (item) => item.getLayerRGBColor(),
        wordBreak: 'break-word',
        sizeUnits: 'meters',
        characterSet,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
      }),
    [polygonCentersWithResidentName]
  );

  return layer;
};

export default useResidentNameLayer;
