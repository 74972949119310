import { RotateMode, ScaleMode, TranslateMode } from '@nebula.gl/edit-modes';

export const ROTATE_MODE = 'rotate';
export const STRETCH_MODE = 'stretch';
export const TRANSLATE_MODE = 'translate';

export const MODES = [
  {
    key: STRETCH_MODE,
    name: 'Stretch mode',
    mode: ScaleMode,
  },
  {
    key: ROTATE_MODE,
    name: 'Rotate mode',
    mode: RotateMode,
  },
  {
    key: TRANSLATE_MODE,
    name: 'Move mode',
    mode: TranslateMode,
  },
];
