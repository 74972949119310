export const RESIDENT_TYPES = {
  // TODO: Replace RESIDENT with ENTITY
  SHOP: 'shop',
  DINE: 'dine',
  ENTERTAINMENT: 'entertainment',
  KIOSK: 'kiosk',
};

export const LINK_TYPES = {
  TITLE: 'title',
  LOGO: 'logo',
};
