import { generateSvgLayerWithColor } from '../helpers/svgLayerConverter';

class CachedSvgLayer {
  constructor() {
    this.images = {};
    this.svgs = {};
  }

  async svgToCanvasWithColor(image, width, height, color) {
    const imageString = await this.getImageString(image);

    if (this.svgs[image]) {
      return this.svgs[image];
    }

    this.svgs[image] = generateSvgLayerWithColor(
      imageString,
      width,
      height,
      color
    );

    return this.svgs[image];
  }

  async getImageString(image) {
    if (this.images[image]) {
      return this.images[image];
    }

    const response = await fetch(image);
    const fileString = await response.text();

    this.images = {
      [image]: fileString,
    };

    return fileString;
  }
}

export default new CachedSvgLayer();
