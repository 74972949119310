import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as geoJsonHelper from '../../helpers/geojson';
import mapService from '../../services/MapService';
import { fireReadyEvent } from '../../services/MessageNotifier';
import polygonService from '../../services/PolygonService';
import {
  MAP_GET,
  MAP_SAVE,
  mapSave as mapSaveAction,
  mapSetAndMarkModified,
  mapSetAndUnmarkModified,
  mapsSet,
  MARK_POLYGON_AS_TYPE,
  REMOVE_MAP_FEATURE,
  setPolygonType,
} from '../actions/mapActions';
import { polygonsSet, removePolygon } from '../actions/polygonActions';
import { floorsSelector } from '../selectors/floorSelectors';
import { activeMapSelector } from '../selectors/mapSelectors';

function* mapGet() {
  try {
    const floors = yield select(floorsSelector);
    const { data: polygonData } = yield call(polygonService.get);
    const { data: mapsData } = yield call(mapService.get);
    yield put(polygonsSet(polygonData));
    yield put(mapsSet({ maps: mapsData, floors }));
    fireReadyEvent();
  } catch (e) {
    console.error(e);
  }
}

export function* mapSave() {
  try {
    const map = yield select(activeMapSelector);
    const { data } = yield call(mapService.save, {
      geojson: JSON.stringify(map.geojson),
      floor: map.floor,
    });

    yield put(
      mapSetAndUnmarkModified({
        map: map.geojson,
        floorId: map.floor,
        id: data.id,
      })
    );
  } catch (e) {
    console.error(e);
  }
}

function* removeMapFeature({ payload }) {
  try {
    const map = yield select(activeMapSelector);

    yield put(
      mapSetAndMarkModified({
        map: geoJsonHelper.getMapWithoutFeature(map, payload),
        floorId: map.floor,
      })
    );

    if (geoJsonHelper.isPolygon(payload)) {
      yield put(removePolygon(payload.properties.id));
    }
  } catch (e) {
    console.error(e);
  }
}

function* markPolygonAsStairs({ payload }) {
  try {
    const map = yield select(activeMapSelector);
    yield put(setPolygonType({ ...payload, map }));
    yield put(mapSaveAction());
  } catch (e) {
    console.error(e);
  }
}

export default function* floorSagas() {
  yield all([
    takeLatest(MAP_GET, mapGet),
    takeLatest(MAP_SAVE, mapSave),
    takeLatest(REMOVE_MAP_FEATURE, removeMapFeature),
    takeLatest(MARK_POLYGON_AS_TYPE, markPolygonAsStairs),
  ]);
}
