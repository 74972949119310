import { RESIDENT_TYPES } from '../../constants/resident';
import { SET_START_POINT_FROM_POLYGON } from '../actions/pathActions';
import createReducer from '../util';

const initialState = {
  startPolygon: null,
};

export default createReducer(
  {
    [SET_START_POINT_FROM_POLYGON]: setStartPointPolygon,
  },
  initialState
);

function setStartPointPolygon(state, { payload }) {
  return {
    ...state,
    startPolygon: {
      id: payload,
      type: RESIDENT_TYPES.KIOSK,
    },
  };
}
