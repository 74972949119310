import { all, call, put, takeLatest } from 'redux-saga/effects';

import kioskService from '../../services/KioskService';
import {
  SELECT_START_POINT_FROM_KIOSK,
  setStartPointFromPolygon,
} from '../actions/pathActions';

function* selectStartPointFromKiosk({ payload }) {
  try {
    const { data } = yield call(kioskService.get, payload);
    yield put(setStartPointFromPolygon(data.id));
  } catch (e) {
    console.error(e);
  }
}

export default function* pathSagas() {
  yield all([
    takeLatest(SELECT_START_POINT_FROM_KIOSK, selectStartPointFromKiosk),
  ]);
}
