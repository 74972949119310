import PolygonBounds from './PolygonBounds';

export default class LocationIconBounds extends PolygonBounds {
  constructor(polygon) {
    super(polygon, null);

    this.xOffset = 0.002;
    this.yOffset = 0.002;
  }
}
