import Canvg from 'canvg';

export const generateSvgLayer = async (image, width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const canvasImage = await Canvg.from(ctx, image);
  await canvasImage.render();

  return canvas;
};

export const generateSvgLayerWithColor = async (
  fileString,
  width,
  height,
  color
) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const svg = getSvgFromString(fileString);

  if (!svg) {
    return canvas;
  }

  svg.setAttribute('fill', color);

  const canvasImage = await Canvg.fromString(ctx, serializeNodeToString(svg));
  await canvasImage.render();

  return canvas;
};

const getSvgFromString = (svgString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(svgString, 'text/xml');

  return xmlDoc.getElementsByTagName('svg')[0];
};

const serializeNodeToString = (node) => {
  const xmlSerializer = new XMLSerializer();

  return xmlSerializer.serializeToString(node);
};
