import DeckGL from '@deck.gl/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Colors } from '../../constants/colors';
import { DEFAULT_MODE, MODES } from '../../constants/map-modes';
import { floorsGet } from '../../store/actions/floorActions';
import { mapGet, viewportSet } from '../../store/actions/mapActions';
import {
  floorsSelector,
  selectedFloorSelector
} from '../../store/selectors/floorSelectors';
import {
  isEditableSelector,
  isMapDrawModeSelector,
  mapViewPortSelector
} from '../../store/selectors/mapSelectors';
import useAtmLayer from './layers/useAtmLayer';
import useFloorLayer from './layers/useFloorLayer';
import useGeoJsonLayer from './layers/useGeoJsonLayer';
import useMobileGeoJsonLayer from './layers/useMobileGeoJsonLayer';
import usePathLayer from './layers/usePathLayer';
import useResidentLogoLayer from './layers/useResidentLogoLayer';
import useResidentNameLayer from './layers/useResidentNameLayer';
import useStairsAndElevatorLayer from './layers/useStairsAndElevatorLayer';
import useStretchResidentInfoLayer from './layers/useStretchResidentInfoLayer';
import MapActionListener from './MapActionListener';
import FloorsMenu from './Menu/Floors';
import MapModes from './Menu/MapModes';

const MapModesContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 13rem;
  background-color: ${Colors.menuBackground};
`;

const Container = styled.div`
  #deckgl-wrapper {
    background: ${Colors.yankeesBlue};
  }
`;

const MapContainer = () => {
  const dispatch = useDispatch();
  const floors = useSelector(floorsSelector);
  const activeFloor = useSelector(selectedFloorSelector);
  const isMapDrawMode = useSelector(isMapDrawModeSelector);
  const isEditable = useSelector(isEditableSelector);
  const viewport = useSelector(mapViewPortSelector);

  const [activeMode, setActiveMode] = useState(DEFAULT_MODE);
  const selectedMode = useMemo(
    () => MODES.find(({ key }) => key === activeMode),
    [activeMode]
  );

  const editableGeoJsonLayer = useGeoJsonLayer(selectedMode);
  const floorLayer = useFloorLayer(activeFloor);
  const [
    mobileGeoJsonLayer,
    isMobileGeoJsonLayerVisible
  ] = useMobileGeoJsonLayer();
  const residentNameLayer = useResidentNameLayer();
  const residentLogoLayers = useResidentLogoLayer();
  const pathLayer = usePathLayer();
  const stairsAndElevatorLayer = useStairsAndElevatorLayer();
  const [
    stretchLogoLayer,
    isStretchLogoLayerVisible
  ] = useStretchResidentInfoLayer();
  const atmLayer = useAtmLayer();

  useEffect(
    () => {
      dispatch(floorsGet());
    },
    [dispatch]
  );

  useEffect(
    () => {
      if (floors.length) {
        dispatch(mapGet());
      }
    },
    [dispatch, floors]
  );

  const renderMenu = () => (
    <MapModesContainer>
      <FloorsMenu />
      {isMapDrawMode && (
        <>
          <MapModes onChange={setActiveMode} activeMode={activeMode} />
        </>
      )}
    </MapModesContainer>
  );

  const layers = useMemo(
    () => [
      floorLayer,
      isEditable && editableGeoJsonLayer,
      isMobileGeoJsonLayerVisible && mobileGeoJsonLayer,
      residentNameLayer,
      residentLogoLayers,
      isStretchLogoLayerVisible && stretchLogoLayer,
      stairsAndElevatorLayer,
      atmLayer,
      ...pathLayer
    ],
    [
      editableGeoJsonLayer,
      floorLayer,
      isEditable,
      isMobileGeoJsonLayerVisible,
      isStretchLogoLayerVisible,
      mobileGeoJsonLayer,
      pathLayer,
      residentLogoLayers,
      stairsAndElevatorLayer,
      stretchLogoLayer,
      residentNameLayer
    ]
  );

  const onViewStateChange = ({ viewState }) => {
    dispatch(viewportSet(viewState));
  };

  return (
    <Container>
      <MapActionListener mode={selectedMode}>
        <DeckGL
          getCursor={() => 'default'}
          initialViewState={viewport}
          controller={{
            touchRotate: true
          }}
          viewState={viewport}
          layers={layers}
          onViewStateChange={onViewStateChange}
        />
      </MapActionListener>
      {isEditable && renderMenu()}
    </Container>
  );
};

export default MapContainer;
