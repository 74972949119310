import { createSelector } from 'reselect';

import { MODES } from '../../constants/map';
import { RESIDENT_TYPES } from '../../constants/resident';
import { isStaffSelector } from './userSelectors';

const mapStateSelector = (state) => state.mapReducer;

export const activeStoreSelector = createSelector(
  mapStateSelector,
  (state) => state.activeStore
);

export const mapsSelector = createSelector(
  mapStateSelector,
  (state) => state.maps
);

export const isKioskSelector = createSelector(
  mapStateSelector,
  (state) => state.activeStore.type === RESIDENT_TYPES.KIOSK
);

export const focusedStoreSelector = createSelector(
  mapStateSelector,
  (state) => state.focusedStore
);

export const isEditableSelector = createSelector(
  mapStateSelector,
  isStaffSelector,
  (mapState, isStaff) => mapState.isEditMode && isStaff
);

export const isPreviewModeSelector = createSelector(
  isEditableSelector,
  (isEditMode) => !isEditMode
);

export const isFocusStoreModeSelector = createSelector(
  mapStateSelector,
  (state) => state.mode === MODES.FOCUS_STORE && !state.isEditMode
);

export const isGoToStoreModeSelector = createSelector(
  mapStateSelector,
  (state) => state.mode === MODES.GO_TO_STORE && !state.isEditMode
);

export const isMapDrawModeSelector = createSelector(
  mapStateSelector,
  isEditableSelector,
  (state, isEditable) => state.mode === MODES.DRAW && isEditable
);

export const isMapSelectStoreModeSelector = createSelector(
  mapStateSelector,
  isEditableSelector,
  (state, isEditable) => state.mode === MODES.SELECT_STORE && isEditable
);

export const activeMapSelector = createSelector(
  (state) => state.floorsReducer,
  mapStateSelector,
  (floorState, mapState) => {
    return mapState.maps.find((map) => map.floor === floorState.selectedFloor);
  }
);

export const isMapModifiedSelector = createSelector(
  mapStateSelector,
  (state) => state.isMapModified
);

export const mapViewPortSelector = createSelector(
  mapStateSelector,
  (state) => state.viewport
);

export const stretchResidentInfoModeSelector = createSelector(
  mapStateSelector,
  (state) => state.stretchResidentInfoMode
);
