import { EditableGeoJsonLayer } from '@nebula.gl/layers';

import { isPolygon } from '../../../../helpers/geojson';

export class ClickableGeoJsonLayer extends EditableGeoJsonLayer {
  onLayerClick({ picks }) {
    if (!picks.length) {
      return;
    }

    const pickedObject = picks[0].object;

    if (!isPolygon(pickedObject)) {
      return;
    }

    const {
      properties: { id },
    } = pickedObject;
    this.props.onSelectLogo(id);
  }
}
