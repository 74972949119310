import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STAIRS_DIRECTIONS } from '../../constants/map';
import {
  DELETE_MODE,
  MARK_ATM,
  MARK_ELEVATOR,
  MARK_STAIRS_DOWN,
  MARK_STAIRS_UP
} from '../../constants/map-modes';
import { RESIDENT_TYPES } from '../../constants/resident';
import { isFeature, isPolygon } from '../../helpers/geojson';
import {
  isMatchingPolygon,
  isPolygonLinked,
  isPolygonWithElevatorOrStairs
} from '../../helpers/map-link';
import * as messageNotifier from '../../services/MessageNotifier';
import {
  focusedStoreSet,
  mapSave,
  markPolygonAsAtm,
  markPolygonAsElevator,
  markPolygonAsStairs,
  removeMapFeature
} from '../../store/actions/mapActions';
import { showStoreShopModal } from '../../store/actions/modalActions';
import {
  isMapSelectStoreModeSelector,
  isPreviewModeSelector
} from '../../store/selectors/mapSelectors';
import { polygonSelector } from '../../store/selectors/polygonSelectors';
import { EDIT_GEO_JSON_LAYER_ID } from './layers/useGeoJsonLayer';
import { MOBILE_GEO_JSON_LAYER_ID } from './layers/useMobileGeoJsonLayer';
import { STRETCH_LAYER_ID } from './layers/useStretchResidentInfoLayer';

const IGNORED_LAYERS = [STRETCH_LAYER_ID];

const STAIRS_MODE = [MARK_STAIRS_DOWN, MARK_STAIRS_UP];

const MapActionListener = ({ mode, children }) => {
  const dispatch = useDispatch();
  const polygons = useSelector(polygonSelector);
  const isMapSelectStoreMode = useSelector(isMapSelectStoreModeSelector);
  const isPreviewMode = useSelector(isPreviewModeSelector);
  const deckRef = useRef(null);

  const onClick = ({ layer }, event) => {
    if (layer && IGNORED_LAYERS.includes(layer.id)) {
      return;
    }

    const { clientX, clientY } = event.srcEvent;
    const pickedObject = deckRef.current.pickObject({
      x: clientX,
      y: clientY,
      radius: 1,
      layerIds: [MOBILE_GEO_JSON_LAYER_ID, EDIT_GEO_JSON_LAYER_ID]
    });

    if (pickedObject && isFeature(pickedObject.object)) {
      handle(pickedObject.object);
    }
  };

  const handle = object => {
    if (isPreviewMode) {
      handleStoreFocusOnClick(object);
      return;
    }

    if (
      isMapSelectStoreMode &&
      isPolygon(object) &&
      !isPolygonWithElevatorOrStairs(object)
    ) {
      dispatch(
        showStoreShopModal({
          polygon: object
        })
      );
      return;
    }

    if (mode.key === DELETE_MODE) {
      dispatch(removeMapFeature(object));
      dispatch(mapSave());
    }

    const isLinked = isPolygonLinked(object, polygons);
    if (STAIRS_MODE.includes(mode.key) && !isLinked) {
      dispatch(
        markPolygonAsStairs({
          polygon: object,
          direction:
            mode.key === MARK_STAIRS_UP
              ? STAIRS_DIRECTIONS.UP
              : STAIRS_DIRECTIONS.DOWN
        })
      );
    }

    if (mode.key === MARK_ELEVATOR && !isLinked) {
      dispatch(markPolygonAsElevator(object));
    }

    if (mode.key === MARK_ATM && !isLinked) {
      dispatch(markPolygonAsAtm(object));
    }
  };

  const handleStoreFocusOnClick = object => {
    const residentPolygon = polygons.find(({ polygon }) =>
      isMatchingPolygon(polygon, object)
    );

    if (
      !residentPolygon ||
      residentPolygon.resident_type === RESIDENT_TYPES.KIOSK
    ) {
      return;
    }

    messageNotifier.sendUserOnResident(residentPolygon);
    dispatch(
      focusedStoreSet({
        resident: residentPolygon.content_object.id,
        type: residentPolygon.resident_type
      })
    );
  };

  const renderChildren = () => {
    const childElement = React.Children.only(children);

    return React.cloneElement(childElement, {
      ref: ref => {
        deckRef.current = ref;
      },
      onClick
    });
  };

  return renderChildren();
};

MapActionListener.propTypes = {
  mode: PropTypes.object,
  children: PropTypes.object
};

export default MapActionListener;
