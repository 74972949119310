import {
  ADD_RESIDENT_POLYGON,
  POLYGONS_SET,
  REMOVE_RESIDENT_POLYGON,
  RESIDENT_POLYGON_BOUNDS_UPDATE,
} from '../actions/polygonActions';
import createReducer from '../util';

const initialState = {
  polygons: [],
};

export default createReducer(
  {
    [POLYGONS_SET]: polygonsSet,
    [ADD_RESIDENT_POLYGON]: addResidentPolygon,
    [REMOVE_RESIDENT_POLYGON]: removeResidentPolygon,
    [RESIDENT_POLYGON_BOUNDS_UPDATE]: residentPolygonBoundsUpdate,
  },
  initialState
);

function polygonsSet(state, { payload }) {
  return {
    ...state,
    polygons: payload.map((residentPolygon) => {
      return {
        ...residentPolygon,
        info_bounds: residentPolygon.info_bounds
          ? JSON.parse(residentPolygon.info_bounds)
          : null,
      };
    }),
  };
}

function addResidentPolygon(state, { payload }) {
  // Remove old polygon instance if it's been updated on same floor
  const polygons = state.polygons.filter((residentPolygon) => {
    return residentPolygon.id !== payload.id;
  });

  return {
    ...state,
    polygons: [...polygons, payload],
  };
}

function removeResidentPolygon(state, { payload }) {
  return {
    ...state,
    polygons: state.polygons.filter(
      (residentPolygon) => residentPolygon.id !== payload.id
    ),
  };
}

function residentPolygonBoundsUpdate(state, { payload }) {
  return {
    ...state,
    polygons: state.polygons.map((residentPolygon) => {
      if (residentPolygon.id === payload.id) {
        return {
          ...residentPolygon,
          info_bounds: payload.bounds.getBounds(),
        };
      }

      return residentPolygon;
    }),
  };
}
