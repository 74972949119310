import { all, call, put, takeLatest } from 'redux-saga/effects';

import authService from '../../services/AuthService';
import { USER_TOKEN_SET,userSet } from '../actions/userActions';

function* authorizeUser({ payload }) {
  try {
    authService.api.attachHeaders({ Authorization: `token ${payload}` });
    const { data } = yield call(authService.authorize);
    yield put(userSet(data));
  } catch (e) {
    throw new Error('Authroization failure');
  }
}

export default function* userSagas() {
  yield all([takeLatest(USER_TOKEN_SET, authorizeUser)]);
}
