import { POLYGON_TYPES } from '../constants/map';

export const isMatchingPolygon = (polygon, selectedPolygon) =>
  polygon.polygon === selectedPolygon.properties.id;

export const getResidentPolygonType = (polygons, selectedPolygon) => {
  const matchinPolygon = polygons.find(({ polygon }) =>
    isMatchingPolygon(polygon, selectedPolygon)
  );

  return matchinPolygon ? matchinPolygon.resident_type : null;
};

export const isSameResident = (activeStore, residentPolygon) =>
  residentPolygon.content_object.id === activeStore.resident &&
  residentPolygon.resident_type === activeStore.type;

export const isSameResidentAndMatchingPolygon = (
  polygons,
  feature,
  activeStore
) =>
  activeStore &&
  polygons.some(
    polygon =>
      isSameResident(activeStore, polygon) &&
      isMatchingPolygon(polygon.polygon, feature)
  );

export const hasResidentLinkedOnFloor = (activeStore, polygons, activeMap) => {
  return polygons.some(residentPolygon =>
    checkIsResidentLinkedOnFloor(activeStore, residentPolygon, activeMap)
  );
};

export const checkIsResidentLinkedOnFloor = (
  store,
  residentPolygon,
  activeMap
) => {
  return (
    isSameResident(store, residentPolygon) &&
    residentPolygon.polygon.map === activeMap.id
  );
};

export const isPolygonLinked = (selectedPolygon, polygons) => {
  return polygons.some(({ polygon: residentPolygon }) =>
    isMatchingPolygon(residentPolygon, selectedPolygon)
  );
};

export const getPolygonType = feature => feature.properties.type;

export const isPolygonWithElevatorOrStairs = feature =>
  [POLYGON_TYPES.ELEVATOR, POLYGON_TYPES.STAIRS].some(
    value => feature.properties.type === value
  );

export const isPolygonATM = feature =>
  feature.properties.type === POLYGON_TYPES.ATM;

export const isPolygonOfType = (feature, type) =>
  feature.properties.type === type;

export const addPropertiesToPolygon = (geojson, polygon, properties) => {
  const polygonIndex = geojson.features.findIndex(
    feature => feature === polygon
  );

  if (polygonIndex > -1) {
    const data = geojson.features[polygonIndex];
    data.properties = {
      ...polygon.properties,
      ...properties
    };

    geojson.features.splice(polygonIndex, 1, data);
  }

  return geojson;
};

export const isSamePolygonId = (first, second) =>
  first.properties.id === second.properties.id;

export const getMapStairsAndElevators = map =>
  map.geojson.features.filter(isPolygonWithElevatorOrStairs);

export const getMapATMs = map => map.geojson.features.filter(isPolygonATM);
