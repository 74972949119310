import { MODALS } from '../../constants/modals';

export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const showStoreShopModal = (payload) => ({
  type: MODAL_SHOW,
  payload: {
    type: MODALS.STORE_SHOP,
    content: payload,
  },
});

export const showChooseStretchMode = () => ({
  type: MODAL_SHOW,
  payload: {
    type: MODALS.CHOOSE_STRETCH,
  },
});

export const closeModal = () => ({
  type: MODAL_CLOSE,
});
