import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import polygonService from '../../services/PolygonService';
import { selectFloor } from '../actions/floorActions';
import { linkPolygonToId, mapSave } from '../actions/mapActions';
import {
  addResidentPolygon,
  CREATE_POLYGON,
  GO_TO_POLYGON_FLOOR,
  REMOVE_POLYGON,
  removeResidentPolygon,
  RESIDENT_POLYGON_BOUNDS_SAVE,
  RESIDENT_POLYGON_REMOVE,
  RESIDENT_POLYGON_SET,
} from '../actions/polygonActions';
import {
  activeMapSelector,
  activeStoreSelector,
  mapsSelector,
} from '../selectors/mapSelectors';
import {
  isPolygonLinkedSelector,
  residentPolygonSelector,
} from '../selectors/polygonSelectors';
import { mapSave as mapSaveSaga } from './mapSagas';

function* createPolygon({ payload }) {
  try {
    let activeMap = yield select(activeMapSelector);

    // Create map if it doesn't exists
    if (!activeMap.id) {
      yield call(mapSaveSaga);
    }

    activeMap = yield select(activeMapSelector);
    const id = uuidv4();
    yield call(polygonService.create, {
      map: activeMap.id,
      polygon: id,
    });
    yield put(linkPolygonToId({ polygon: payload, id, map: activeMap }));
    yield put(mapSave());
  } catch (e) {
    console.error(e);
  }
}

function* removePolygon({ payload }) {
  try {
    const activeMap = yield select(activeMapSelector);
    yield call(polygonService.delete, payload);
    yield put(linkPolygonToId({ polygon: payload, map: activeMap, id: null }));
    yield put(mapSave());
  } catch (e) {
    console.error(e);
  }
}

function* residentPolygonSet({ payload }) {
  try {
    const { polygon, linkType } = payload;
    const activeMap = yield select(activeMapSelector);
    const activeStore = yield select(activeStoreSelector);
    const isPolygonLinked = yield select(isPolygonLinkedSelector(polygon));

    if (isPolygonLinked) {
      yield dispatchUnlinkPolygon(polygon);
    }

    const { data } = yield call(polygonService.link, {
      polygon: polygon.properties.id,
      map: activeMap.id,
      resident_id: activeStore.resident,
      resident_type: activeStore.type,
      link_type: linkType,
    });
    yield put(addResidentPolygon(data));
  } catch (e) {
    console.error(e);
  }
}

function* residentPolygonBoundsSave({ payload }) {
  try {
    yield call(polygonService.updateBounds, payload.id, {
      info_bounds: JSON.stringify(payload.bounds.getBounds()),
    });
  } catch (e) {
    console.error(e);
  }
}

function* residentPolygonRemove({ payload }) {
  try {
    yield dispatchUnlinkPolygon(payload);
  } catch (e) {
    console.error(e);
  }
}

function* dispatchUnlinkPolygon(polygon) {
  const residentPolygon = yield select(residentPolygonSelector(polygon));
  yield call(polygonService.unlink, residentPolygon.id);
  yield put(removeResidentPolygon(residentPolygon));
}

function* goToPolygonFloor({ payload }) {
  try {
    const maps = yield select(mapsSelector);
    const polygonMap = maps.find((map) => map.id === payload.polygon.map);

    if (polygonMap) {
      yield put(selectFloor(polygonMap.floor));
    }
  } catch (e) {
    console.error(e);
  }
}

export default function* userSagas() {
  yield all([
    takeLatest(CREATE_POLYGON, createPolygon),
    takeLatest(REMOVE_POLYGON, removePolygon),
    takeLatest(RESIDENT_POLYGON_SET, residentPolygonSet),
    takeLatest(RESIDENT_POLYGON_REMOVE, residentPolygonRemove),
    takeLatest(RESIDENT_POLYGON_BOUNDS_SAVE, residentPolygonBoundsSave),
    takeLatest(GO_TO_POLYGON_FLOOR, goToPolygonFloor),
  ]);
}
