import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  isChooseStretchModeModalVisibleSelector,
  isStoreShopModalVisibleSelector,
} from '../../store/selectors/modalSelectors';
import ChooseStretchMode from './ChooseStretchMode';
import StoreShopModal from './StoreShopModal';

function Modals({ children }) {
  const isStoreShopModalVisible = useSelector(isStoreShopModalVisibleSelector);
  const isChooseStretchModeVisible = useSelector(
    isChooseStretchModeModalVisibleSelector
  );

  return (
    <>
      {isStoreShopModalVisible && (
        <StoreShopModal isOpen={isStoreShopModalVisible} />
      )}
      {isChooseStretchModeVisible && (
        <ChooseStretchMode isOpen={isChooseStretchModeVisible} />
      )}
      {children}
    </>
  );
}

Modals.propTypes = {
  children: PropTypes.object,
};

export default Modals;
