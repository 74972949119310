import { FlyToInterpolator } from '@deck.gl/core';
import centerOfMass from '@turf/center-of-mass';

import { DEFAULT_GEO_JSON, MODES } from '../../constants/map';
import { getCoordinates } from '../../helpers/geojson';
import * as mapLink from '../../helpers/map-link';
import {
  ACTIVE_MAP_SET,
  ACTIVE_STORE_SET,
  FOCUS_STORE_VIEWPORT,
  FOCUSED_STORE_SET,
  IS_EDIT_MODE,
  LINK_POLYGON_TO_ID,
  MAPS_SET,
  NAVIGATE_TO_STORE_SET,
  RESET_MAP_FOCUS,
  SET_POLYGON_TYPE,
  SET_VIEWPORT_TO_POLYGON,
  STRETCH_RESIDENT_INFO_MODE_SET,
  VIEWPORT_SET,
} from '../actions/mapActions';
import createReducer from '../util';

const DEFAULT_VIEWPORT = {
  longitude: -122.47269,
  latitude: 37.7903,
  zoom: 10.8,
  maxZoom: 40,
  minZoom: 10.8,
  transitionDuration: 500,
  bearing: 90,
  transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
};

const initialState = {
  maps: [],
  defaultViewport: DEFAULT_VIEWPORT,
  viewport: DEFAULT_VIEWPORT,
  isEditMode: false,
  activeStore: null,
  focusedStore: null,
  mode: MODES.DRAW,
  stretchResidentInfoMode: null,
  isMapModified: false,
  history: [],
};

const longitudeBoundries = {
  left: -122.55939436280497,
  right: -122.37939436280497,
};

const latitudeBoundries = {
  top: 37.9367,
  bottom: 37.6953,
};

export default createReducer(
  {
    [MAPS_SET]: mapsSet,
    [ACTIVE_MAP_SET]: activeMapUpdate,
    [ACTIVE_STORE_SET]: activeStoreSet,
    [FOCUSED_STORE_SET]: focusedStoreSet,
    [NAVIGATE_TO_STORE_SET]: navigateToStoreSet,
    [LINK_POLYGON_TO_ID]: linkPolygonToId,
    [IS_EDIT_MODE]: isEditModeSet,
    [FOCUS_STORE_VIEWPORT]: focusStoreViewPort,
    [VIEWPORT_SET]: viewportSet,
    [RESET_MAP_FOCUS]: resetMapFocus,
    [STRETCH_RESIDENT_INFO_MODE_SET]: setStretchResidentInfoMode,
    [SET_POLYGON_TYPE]: setPolygonType,
    [SET_VIEWPORT_TO_POLYGON]: setViewportToPolygon,
  },
  initialState
);

function mapsSet(state, action) {
  const { maps, isMapModified, floors } = action.payload;

  const mapsPerFloor = floors.map((floor) => {
    const map = maps.find((item) => item.floor === floor.id);

    return (
      map || { geojson: JSON.stringify(DEFAULT_GEO_JSON), floor: floor.id }
    );
  });

  return {
    ...state,
    maps: mapsPerFloor.map((map) => {
      map.geojson = map.geojson ? JSON.parse(map.geojson) : DEFAULT_GEO_JSON;
      return map;
    }),
    isMapModified,
  };
}

function activeMapUpdate(state, action) {
  const { map, floorId, isMapModified, id } = action.payload;

  return {
    ...state,
    maps: state.maps.map((item) => {
      if (item.floor !== floorId) {
        return item;
      }

      return {
        ...item,
        geojson: map,
        id: item.id || id,
      };
    }),
    isMapModified,
  };
}

function linkPolygonToId(state, action) {
  const { polygon, map, id } = action.payload;

  return {
    ...state,
    maps: updateGeoJsonOnMap(state.maps, map.floor, (oldGeoJson) =>
      mapLink.addPropertiesToPolygon(oldGeoJson, polygon, { id })
    ),
  };
}

function updateGeoJsonOnMap(maps, floorId, getGeoJsonCallback) {
  return maps.map((item) => {
    if (item.floor !== floorId) {
      return item;
    }

    return {
      ...item,
      geojson: getGeoJsonCallback(item.geojson),
    };
  });
}

function activeStoreSet(state, action) {
  return {
    ...state,
    activeStore: action.payload,
    mode: MODES.SELECT_STORE,
  };
}

function focusedStoreSet(state, action) {
  return {
    ...state,
    focusedStore: action.payload,
    mode: MODES.FOCUS_STORE,
  };
}

function navigateToStoreSet(state, action) {
  return {
    ...state,
    focusedStore: action.payload,
    mode: MODES.GO_TO_STORE,
  };
}

function isEditModeSet(state, action) {
  return {
    ...state,
    isEditMode: action.payload,
  };
}

function focusStoreViewPort(state, action) {
  const polygon = action.payload;
  const coordinates = getCoordinates(centerOfMass(polygon));

  return {
    ...state,
    viewport: {
      ...state.viewport,
      longitude: coordinates[0],
      latitude: coordinates[1],
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
      zoom: state.viewport.minZoom + 1.5,
      bearing: 90,
    },
  };
}

function setViewportToPolygon(state, action) {
  const polygon = action.payload;
  const coordinates = getCoordinates(centerOfMass(polygon));

  const newViewport = {
    ...state.defaultViewport,
    longitude: coordinates[0],
    latitude: coordinates[1],
    zoom: state.viewport.minZoom,
    bearing: 90,
  };

  return {
    ...state,
    defaultViewport: newViewport,
    viewport: newViewport,
  };
}

function viewportSet(state, action) {
  const newViewState = {
    ...state.viewport,
    ...action.payload,
  };

  const longitude = getBoundries(
    newViewState.longitude,
    longitudeBoundries.left,
    longitudeBoundries.right
  );

  if (longitude) {
    newViewState.longitude = longitude;
  }

  const latitude = getBoundries(
    newViewState.latitude,
    latitudeBoundries.bottom,
    latitudeBoundries.top
  );

  if (latitude) {
    newViewState.latitude = latitude;
  }

  return {
    ...state,
    viewport: newViewState,
  };
}

function getBoundries(coordinate, lowerBoundry, higherBoundry) {
  const lower = coordinate < lowerBoundry && lowerBoundry;
  const higher = coordinate > higherBoundry && higherBoundry;

  return lower || higher;
}

function resetMapFocus(state, action) {
  return {
    ...state,
    viewport: {
      ...state.defaultViewport,
      ...action.payload,
    },
    mode: MODES.SELECT_STORE,
    focusedStore: null,
  };
}

function setStretchResidentInfoMode(state, action) {
  return {
    ...state,
    stretchResidentInfoMode: action.payload,
  };
}

function setPolygonType(state, action) {
  const { polygon, map, type, direction } = action.payload;
  const polygonDirections = polygon.properties.directions || [];
  const newDirections =
    polygonDirections.length && polygonDirections.includes(direction)
      ? polygonDirections.filter((oldDirection) => oldDirection !== direction)
      : [...polygonDirections, direction];

  return {
    ...state,
    maps: updateGeoJsonOnMap(state.maps, map.floor, (oldGeoJson) =>
      mapLink.addPropertiesToPolygon(oldGeoJson, polygon, {
        type:
          mapLink.getPolygonType(polygon) && !newDirections.length
            ? null
            : type,
        directions: newDirections,
      })
    ),
  };
}
