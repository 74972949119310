import { Colors } from '../../../constants/colors';
import { RESIDENT_TYPES } from '../../../constants/resident';
import { isPolygon } from '../../../helpers/geojson';
import {
  getResidentPolygonType,
  isPolygonLinked,
  isSameResidentAndMatchingPolygon,
} from '../../../helpers/map-link';

export const residentTypeToColor = (type) => {
  switch (type) {
  case RESIDENT_TYPES.SHOP:
    return Colors.shopPolygon;
  case RESIDENT_TYPES.DINE:
    return Colors.dinePolygon;
  case RESIDENT_TYPES.ENTERTAINMENT:
    return Colors.entertainmentPolygon;
  case RESIDENT_TYPES.KIOSK:
    return Colors.kioskPolygon;

  default:
    return Colors.polygonNeutral;
  }
};

export const getFillColor = (polygons, feature, activeStore) => {
  if (
    isPolygon(feature) &&
    isSameResidentAndMatchingPolygon(polygons, feature, activeStore)
  ) {
    return Colors.poligonLinked;
  }

  if (isPolygon(feature) && feature.properties.type) {
    return Colors.polygonWithType;
  }

  if (!isPolygon(feature) || !isPolygonLinked(feature, polygons)) {
    return Colors.polygonNeutral;
  }

  return residentTypeToColor(getResidentPolygonType(polygons, feature));
};
