import { loadAppFont } from '../constants/fonts';
import store from '../store';
import { selectFloor } from '../store/actions/floorActions';
import {
  activeStoreSet,
  isEditModeSet,
  viewportSet,
} from '../store/actions/mapActions';
import { selectStartPointFromKiosk } from '../store/actions/pathActions';
import { userTokenSet } from '../store/actions/userActions';

const USER_TOKEN_KEY = 'token';
const MAPS_RESIDENT_KEY = 'resident_id';
const MAPS_RESIDENT_TYPE_KEY = 'resident_type';
const IS_EDIT_MODE = 'is_edit';
const ZOOM_PARAM = 'zoom';
const MAX_ZOOM_PARAM = 'max_zoom';
const SELECTED_FLOOR_ID = 'selected_floor_id';
const KIOSK_MAC_ADDRESS = 'kiosk_mac_address';

const QUERY_TO_DISPATCH = [
  {
    isAvailable: (searchParams) => searchParams.has(USER_TOKEN_KEY),
    dispatch(searchParams) {
      const value = searchParams.get(USER_TOKEN_KEY);
      store.dispatch(userTokenSet(value));
    },
  },
  {
    isAvailable: (searchParams) =>
      searchParams.has(MAPS_RESIDENT_KEY) &&
      searchParams.has(MAPS_RESIDENT_TYPE_KEY),
    dispatch(searchParams) {
      const value = {
        resident: Number(searchParams.get(MAPS_RESIDENT_KEY)),
        type: searchParams.get(MAPS_RESIDENT_TYPE_KEY),
      };
      store.dispatch(activeStoreSet(value));
    },
  },
  {
    isAvailable: (searchParams) => searchParams.has(IS_EDIT_MODE),
    dispatch(searchParams) {
      store.dispatch(isEditModeSet(!!searchParams.get(IS_EDIT_MODE) || false));
    },
  },
  {
    isAvailable: (searchParams) =>
      searchParams.has(ZOOM_PARAM) && searchParams.has(MAX_ZOOM_PARAM),
    dispatch(searchParams) {
      const zoom = Number(searchParams.get(ZOOM_PARAM));
      const maxZoom = Number(searchParams.get(MAX_ZOOM_PARAM));
      store.dispatch(viewportSet({ minZoom: zoom, zoom, maxZoom }));
    },
  },
  {
    isAvailable: (searchParams) => searchParams.has(SELECTED_FLOOR_ID),
    dispatch(searchParams) {
      store.dispatch(selectFloor(Number(searchParams.get(SELECTED_FLOOR_ID))));
    },
  },
  {
    isAvailable: (searchParams) => searchParams.has(KIOSK_MAC_ADDRESS),
    dispatch(searchParams) {
      store.dispatch(
        selectStartPointFromKiosk(searchParams.get(KIOSK_MAC_ADDRESS))
      );
    },
  },
];

export async function init() {
  const url = window.location.href;
  const urlParts = url.split('?');
  const searchParams = new URLSearchParams(urlParts[1]);

  QUERY_TO_DISPATCH.forEach((item) => {
    if (item.isAvailable(searchParams)) {
      item.dispatch(searchParams);
    }
  });

  await loadAppFont();

  return Promise.resolve();
}
