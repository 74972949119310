import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { useDispatch, useSelector } from 'react-redux';

import { isPolygon } from '../../../helpers/geojson';
import {
  mapSave,
  mapSetAndMarkModified,
} from '../../../store/actions/mapActions';
import { createPolygon } from '../../../store/actions/polygonActions';
import { selectedFloorSelector } from '../../../store/selectors/floorSelectors';
import {
  activeMapSelector,
  activeStoreSelector,
} from '../../../store/selectors/mapSelectors';
import { polygonSelector } from '../../../store/selectors/polygonSelectors';
import * as polygonColors from '../helpers/polygonColors';

export const EDIT_GEO_JSON_LAYER_ID = 'geojson';

const ADD_FEATURE_ACTION = 'addFeature';
const FINISH_MOVE_POSITION = 'finishMovePosition';
const ADD_TENTATIVE_POSITION = 'addTentativePosition';

const SAVE_ACTIONS = [
  ADD_FEATURE_ACTION,
  FINISH_MOVE_POSITION,
  ADD_TENTATIVE_POSITION,
];

const useGeoJsonLayer = (activeMode) => {
  const dispatch = useDispatch();
  const activeMap = useSelector(activeMapSelector);
  const activeFloor = useSelector(selectedFloorSelector);
  const polygons = useSelector(polygonSelector);

  const activeStore = useSelector(activeStoreSelector);

  const handleAddFeature = (updatedData, featureIndexes) => {
    const feature = updatedData.features[featureIndexes[0]];
    if (isPolygon(feature)) {
      dispatch(createPolygon(feature));
      return;
    }

    dispatch(mapSave());
  };

  const onEdit = ({
    updatedData,
    editType,
    editContext: { featureIndexes },
  }) => {
    dispatch(
      mapSetAndMarkModified({ map: updatedData, floorId: activeFloor.id })
    );

    if (editType === ADD_FEATURE_ACTION) {
      handleAddFeature(updatedData, featureIndexes);
      return;
    }

    if (SAVE_ACTIONS.includes(editType)) {
      dispatch(mapSave());
    }
  };

  const getPolygonColor = (feature) => {
    return polygonColors.getFillColor(polygons, feature, activeStore);
  };

  return activeMap
    ? new EditableGeoJsonLayer({
      id: EDIT_GEO_JSON_LAYER_ID,
      data: activeMap.geojson,
      mode: activeMode.mode,
      getFillColor: getPolygonColor,
      getLineColor: getPolygonColor,
      selectedFeatureIndexes: activeMap.geojson.features.map(
        (value, index) => index
      ),
      onEdit,
    })
    : null;
};

export default useGeoJsonLayer;
