import { Colors } from '../constants/colors';
import { LINK_TYPES } from '../constants/resident';
import * as geoJsonCalculator from '../helpers/geoJsonCalculator';
import { getImageUrl } from '../helpers/image';
import cachedSvgLogoLayer from '../services/CachedLogoSvgLayer';
import PolygonBounds from './PolygonBounds';

export default class ResidentPolygonBlock {
  constructor(residentPolygon, polygon, isFocused) {
    this.id = residentPolygon.id;
    this.resident = residentPolygon.content_object;
    this.type = residentPolygon.resident_type;
    this.bounds = new PolygonBounds(
      polygon,
      residentPolygon.info_bounds,
      residentPolygon.content_object.dimensions,
      residentPolygon.link_type
    );
    this.linkType = residentPolygon.link_type;
    this.polygon = polygon;
    this.isFocused = isFocused;
  }

  getArea() {
    return geoJsonCalculator.polygonSize(this.polygon);
  }

  getResident() {
    return this.resident;
  }

  getDisplayTitle() {
    return this.resident.title.toUpperCase();
  }

  isImageVisible() {
    return this.resident.logo && this.linkType === LINK_TYPES.LOGO;
  }

  getImageLayer() {
    return cachedSvgLogoLayer.svgToCanvasWithColor(
      getImageUrl(this.resident.logo),
      1000,
      500,
      this.getLayerHEXColor()
    );
  }

  getIdentifier() {
    return `logo-layer-${this.resident.id}-${this.type}`;
  }

  getLayerRGBColor() {
    return this.isFocused
      ? Colors.focusedResidentInfoColor
      : Colors.residenInfoColor;
  }

  getLayerHEXColor() {
    return this.isFocused ? Colors.black : Colors.white;
  }

  isOneOfTypes(types) {
    return types.includes(this.type);
  }
}
